import {
	HttpClient,
	HttpErrorResponse,
	HttpHeaders,
	HttpRequest
} from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { Country } from '../models/country'
import { AuthTokenType } from './../models/auth-token-type'
import { OfferList, OfferReport, OfferRequest, OfferResponse } from './../models/offer'
import { ApiConfigService } from './api-config.service'
import { APP_CONFIG, IAppConfig } from './app.config'
import { TokenStoreService } from './token-store.service'

@Injectable()
export class OfferService {
	private headers = new HttpHeaders()
	private apiPath: string

	constructor(
		private http: HttpClient,
		private router: Router,
		@Inject(APP_CONFIG) private appConfig: IAppConfig,
		private apiConfigService: ApiConfigService,
		private tokenStoreService: TokenStoreService
	) {
		this.headers = this.getBearerAuthHeader()
	}

	getBearerAuthHeader(): HttpHeaders {
		return new HttpHeaders({
			'Content-Type': 'application/json',
			Authorization: `Bearer ${this.tokenStoreService.getRawAuthToken(
				AuthTokenType.AccessToken
			)}`
		})
	}
	getCountries(): Observable<Country[]> {
		this.apiPath = `${this.appConfig.apiEndpoint}/offer/getCountries`
		return this.http.get<Country[]>(this.apiPath, { headers: this.headers })
	}

	searchOffers(companyId: string):Observable<OfferList[]>
	{
		this.apiPath = `${
			this.appConfig.apiEndpoint
		}/offer/searchOffers?companyId=${companyId}`
		return this.http.get<OfferList[]>(this.apiPath, {
			headers: this.headers
		})
	}

	getOffers(companyId: string): Observable<OfferResponse[]> {
		this.apiPath = `${
			this.appConfig.apiEndpoint
		}/offer/getOffers?companyId=${companyId}`
		return this.http.get<OfferResponse[]>(this.apiPath, {
			headers: this.headers
		})
	}
	getTodayOperations(companyId: string) : Observable<OfferList[]>{
		this.apiPath = `${
			this.appConfig.apiEndpoint
		}/offer/getTodayOperations?companyId=${companyId}`
		return this.http.get<OfferList[]>(this.apiPath, {
			headers: this.headers
		})
	}
	getFileTrackings(companyId: string) : Observable<OfferList[]>{
		this.apiPath = `${
			this.appConfig.apiEndpoint
		}/offer/getFileTrackings?companyId=${companyId}`
		return this.http.get<OfferList[]>(this.apiPath, {
			headers: this.headers
		})
	}
	getVolumeReport(companyId: string, dStart: string, dEnd: string, tType: string, oType: string, type : string) : Observable<OfferReport[]>
	{
		this.apiPath = `${
			this.appConfig.apiEndpoint
		}/offer/getReport?companyId=${companyId}&dStart=${dStart}&dEnd=${dEnd}&tType=${tType}&oType=${oType}&type=${type}`
		return this.http.get<OfferReport[]>(this.apiPath,{
			headers: this.headers
		})
	}
	getOffer(id: string): Observable<OfferResponse> {
		this.apiPath = `${this.appConfig.apiEndpoint}/offer/getOffer?id=${id}`
		return this.http.get<OfferResponse>(this.apiPath, { headers: this.headers })
	}
	getTCMBCurrencies() {
		this.apiPath = `${this.appConfig.apiEndpoint}/offer/getTCMBCurrencies`
		return this.http.get<any[]>(this.apiPath, { headers: this.headers })
	}
	lastInsertedId(): Observable<number> {
		this.apiPath = `${this.appConfig.apiEndpoint}/offer/lastInsertedId`
		return this.http.get<number>(this.apiPath, { headers: this.headers })
	}
	downloadCover(offer: OfferRequest): Observable<string> {
		this.apiPath = `${this.appConfig.apiEndpoint}/offer/downloadCover`
		return this.http.post<string>(this.apiPath, JSON.stringify(offer), {
			headers: this.headers
		})
	}
	addOffer(offer: OfferRequest): Observable<boolean> {
		this.apiPath = `${this.appConfig.apiEndpoint}/offer/addOffer`
		return this.http
			.post(this.apiPath, JSON.stringify(offer), { headers: this.headers })
			.pipe(
				map(
					res => {
						if (res) {
							return true
						}
						return false
					},
					(err: HttpErrorResponse) => {
						console.log(err.error)
					}
				)
			)
	}
	uploadChestImage(formData: FormData) {
		const uploadReq = new HttpRequest(
			'POST',
			`${this.appConfig.apiEndpoint}/offer/uploadChestImage`,
			formData,
			{
				reportProgress: true
			}
		)
		return this.http.request(uploadReq)
	}
	updateOffer(user: OfferResponse): Observable<boolean> {
		this.apiPath = `${this.appConfig.apiEndpoint}/offer/updateOffer`
		return this.http
			.put(this.apiPath, JSON.stringify(user), { headers: this.headers })
			.pipe(
				map(
					res => {
						if (res) {
							return true
						}
						return false
					},
					(err: HttpErrorResponse) => {
						console.log(err.error)
					}
				)
			)
	}
	getBase64Image(fileName: string): Observable<string> {
		this.apiPath = `${
			this.appConfig.apiEndpoint
		}/offer/chestImageToBase64?fileName=${fileName}`
		return this.http.get<string>(this.apiPath, { headers: this.headers })
	}
	deleteOffer(id: string): Observable<boolean> {
		this.apiPath = `${this.appConfig.apiEndpoint}/offer/deleteOffer?id=${id}`
		return this.http.delete(this.apiPath, { headers: this.headers }).pipe(
			map(
				res => {
					if (res) {
						return true
					}
					return false
				},
				(err: HttpErrorResponse) => {
					console.log(err.error)
				}
			)
		)
	}
}
