import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse, HttpRequest, HttpEventType } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject ,  Observable } from "rxjs";
import { ErrorObservable } from "rxjs/observable/ErrorObservable";
import { catchError, finalize, map } from "rxjs/operators";

import { AuthTokenType } from "./../models/auth-token-type";
import { ApiConfigService } from "./api-config.service";
import { APP_CONFIG, IAppConfig } from "./app.config";
import { TokenStoreService } from "./token-store.service";
import { InvoiceResponse, InvoiceRequest } from "../models/invoice";
import { PersonelToInvoiceRequest } from "../models/personel-to-invoice";
import { PersonelResponse } from "../models/personel";
import { InvoiceDocumentResponse } from "../models/invoice-document";

@Injectable()
export class InvoiceService {

  private headers=new HttpHeaders();
  private apiPath: string;

  constructor(
    private http: HttpClient,
    private router: Router,
    @Inject(APP_CONFIG) private appConfig: IAppConfig,
    private apiConfigService: ApiConfigService,
    private tokenStoreService: TokenStoreService
  ) 
  {
    this.headers = this.getBearerAuthHeader();
  }


  getBearerAuthHeader(): HttpHeaders {
    return new HttpHeaders({
      "Content-Type": "application/json",
      "Authorization": `Bearer ${this.tokenStoreService.getRawAuthToken(AuthTokenType.AccessToken)}`
    });
  }
	downloadInvoice(invoice:InvoiceRequest,invoiceNumber:number):Observable<string>{
		this.apiPath = `${this.appConfig.apiEndpoint}/invoice/downloadInvoice?invoiceNumber=${invoiceNumber}`;
		return this.http.post<string>(this.apiPath, JSON.stringify(invoice), { headers: this.headers });
  }
  downloadCostInvoice(invoice:InvoiceRequest,invoiceNumber:number):Observable<string>{
		this.apiPath = `${this.appConfig.apiEndpoint}/invoice/downloadCostInvoice?invoiceNumber=${invoiceNumber}`;
		return this.http.post<string>(this.apiPath, JSON.stringify(invoice), { headers: this.headers });
	}
  getInvoices(companyId: string): Observable<InvoiceResponse[]> {
    this.apiPath=`${this.appConfig.apiEndpoint}/invoice/getInvoices?companyId=${companyId}`;
    return this.http.get<InvoiceResponse[]>(this.apiPath, {headers: this.headers});
  }

  getInvoice(id: string): Observable<InvoiceResponse> {
    this.apiPath=`${this.appConfig.apiEndpoint}/invoice/getInvoice?id=${id}`;
    return this.http.get<InvoiceResponse>(this.apiPath, {headers: this.headers});
  }
  
  getInvoiceByOfferId(offerId: string): Observable<InvoiceResponse> {
    this.apiPath=`${this.appConfig.apiEndpoint}/invoice/getInvoiceByOfferId?offerId=${offerId}`;
    return this.http.get<InvoiceResponse>(this.apiPath, {headers: this.headers});
  }

  addInvoice(invoice: InvoiceRequest): Observable<boolean> {
    this.apiPath=`${this.appConfig.apiEndpoint}/invoice/addInvoice`;
    return this.http.post(this.apiPath, JSON.stringify(invoice), {headers: this.headers}).pipe(map(
      res => {
        if (res) {
          return true;
        }
        return false;
      },
      (err: HttpErrorResponse) => {
        console.log(err.error);
      }
    ));
  }
  
  updateInvoice(invoice: InvoiceResponse): Observable<boolean> {
    this.apiPath=`${this.appConfig.apiEndpoint}/invoice/updateInvoice`;
    return this.http.put(this.apiPath, JSON.stringify(invoice), {headers: this.headers}).pipe(map(
      res => {
        if (res) {
          return true;
        }
        return false;
      },
      (err: HttpErrorResponse) => {
        console.log(err.error);
      }
    ));
  } 

  deleteInvoice(id: string): Observable<boolean> {
    this.apiPath=`${this.appConfig.apiEndpoint}/invoice/deleteInvoice?id=${id}`;
    return this.http.delete(this.apiPath, {headers: this.headers}).pipe(map(
      res => {
        if (res) {
          return true;
        }
        return false;
      },
      (err: HttpErrorResponse) => {
        console.log(err.error);
      }
    ));
  }   
}
