import {
	HttpClient,
	HttpErrorResponse,
	HttpHeaders
} from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { StockTrackingResponse } from '../models/stock-tracking'
import { WarehouseRequest, WarehouseResponse } from '../models/warehouse'
import { AuthTokenType } from './../models/auth-token-type'
import { ApiConfigService } from './api-config.service'
import { APP_CONFIG, IAppConfig } from './app.config'
import { TokenStoreService } from './token-store.service'

@Injectable()
export class StoreService {
	private headers = new HttpHeaders()
	private apiPath: string

	constructor(
		private http: HttpClient,
		private router: Router,
		@Inject(APP_CONFIG) private appConfig: IAppConfig,
		private apiConfigService: ApiConfigService,
		private tokenStoreService: TokenStoreService
	) {
		this.headers = this.getBearerAuthHeader()
	}

	getBearerAuthHeader(): HttpHeaders {
		return new HttpHeaders({
			'Content-Type': 'application/json',
			Authorization: `Bearer ${this.tokenStoreService.getRawAuthToken(
				AuthTokenType.AccessToken
			)}`
		})
	}

	getStockTrackings(): Observable<StockTrackingResponse[]> {
		this.apiPath = `${this.appConfig.apiEndpoint}/store/getStockTrackings`
		return this.http.get<StockTrackingResponse[]>(this.apiPath, {
			headers: this.headers
		})
	}
	getWarehouses(companyId): Observable<WarehouseResponse[]> {
		this.apiPath = `${
			this.appConfig.apiEndpoint
		}/store/getWarehouses?companyId=${companyId}`
		return this.http.get<WarehouseResponse[]>(this.apiPath, {
			headers: this.headers
		})
	}
	addWarehouse(warehouse: WarehouseRequest): Observable<boolean> {
		this.apiPath = `${this.appConfig.apiEndpoint}/store/addWarehouse`
		return this.http
			.post(this.apiPath, JSON.stringify(warehouse), { headers: this.headers })
			.pipe(
				map(
					res => {
						if (res) {
							return true
						}
						return false
					},
					(err: HttpErrorResponse) => {
						console.log(err.error)
					}
				)
			)
	}
	
	getEvents(companyId: string, pDate: string): Observable<any[]> {
		this.apiPath = `${this.appConfig.apiEndpoint}/store/getEvents?companyId=${companyId}&pDate=${pDate}`;
		return this.http.get<any[]>(this.apiPath, { headers: this.headers })
	}
	updateStockTrackings(
		stockTrackings: StockTrackingResponse[]
	): Observable<boolean> {
		this.apiPath = `${this.appConfig.apiEndpoint}/store/updateStockTrackings`
		return this.http
			.put(this.apiPath, JSON.stringify(stockTrackings), {
				headers: this.headers
			})
			.pipe(
				map(
					res => {
						if (res) {
							return true
						}
						return false
					},
					(err: HttpErrorResponse) => {
						console.log(err.error)
					}
				)
			)
	}
	updateWarehouses(warehouses: WarehouseResponse[]): Observable<boolean> {
		this.apiPath = `${this.appConfig.apiEndpoint}/store/updateWarehouseRange`
		return this.http
			.put(this.apiPath, JSON.stringify(warehouses), { headers: this.headers })
			.pipe(
				map(
					res => {
						if (res) {
							return true
						}
						return false
					},
					(err: HttpErrorResponse) => {
						console.log(err.error)
					}
				)
			)
	}
}
