import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AddEventRequest } from "../models/AddEventRequest";
import { RoleTypeShowEvents } from "../models/RoleTypeShowEvent";
import { UserEventResponse } from "../models/UserEvent";

import { AuthTokenType } from "./../models/auth-token-type";
import { ApiConfigService } from "./api-config.service";
import { APP_CONFIG, IAppConfig } from "./app.config";
import { TokenStoreService } from "./token-store.service";


@Injectable()
export class CustomEventService {

  private headers = new HttpHeaders();
  private apiPath: string;

  constructor(
    private http: HttpClient,
    private router: Router,
    @Inject(APP_CONFIG) private appConfig: IAppConfig,
    private apiConfigService: ApiConfigService,
    private tokenStoreService: TokenStoreService
  ) {
    this.headers = this.getBearerAuthHeader();
  }


  getBearerAuthHeader(): HttpHeaders {
    return new HttpHeaders({
      "Content-Type": "application/json",
      "Authorization": `Bearer ${this.tokenStoreService.getRawAuthToken(AuthTokenType.AccessToken)}`
    });
  }
  getEventByUserId(Id: string): Observable<UserEventResponse[]> {
    this.apiPath=`${this.appConfig.apiEndpoint}/event/getEventsByUserId?userId=${Id}`;
    return this.http.get<UserEventResponse[]>(this.apiPath, {headers: this.headers});
  }
  updateEvent(customEvent: AddEventRequest): Observable<boolean> {
    this.apiPath=`${this.appConfig.apiEndpoint}/event/updateEvents`;
    return this.http.put(this.apiPath, JSON.stringify(customEvent), {headers: this.headers}).pipe(map(
      res => {
        if (res) {
          return true;
        }
        return false;
      },
      (err: HttpErrorResponse) => {
        console.log(err.error);
      }
    ));
  } 
  RoleTypeShowEvent(roleType: RoleTypeShowEvents): Observable<boolean> {
    this.apiPath=`${this.appConfig.apiEndpoint}/event/showRoleTypeInEvent`;
    return this.http.post(this.apiPath, JSON.stringify(roleType), {headers: this.headers}).pipe(map(
      res => {
        if (res) {
          return true;
        }
        return false;
      },
      (err: HttpErrorResponse) => {
        console.log(err.error);
      }
    ));
  } 
  RoleTypeEditEventPrivateNote(eventId: number, userId: number): Observable<boolean> {
    this.apiPath=`${this.appConfig.apiEndpoint}/event/RoleTypeEditEventPrivateNote?eventId=${eventId}&userId=${userId}`;
    return this.http.delete(this.apiPath, {headers: this.headers}).pipe(map(
      res => {
        if (res) {
          return true;
        }
        return false;
      },
      (err: HttpErrorResponse) => {
        console.log(err.error);
      }
    ));
  } 
  
  addEvent(customEvent: AddEventRequest): Observable<Object> {
    this.apiPath = `${this.appConfig.apiEndpoint}/event/addEvent`;
    return this.http.post(this.apiPath, JSON.stringify(customEvent), { headers: this.headers }).pipe(map(
      res => {
        if (res) {
          return res;
          
        }
      },
      (err: HttpErrorResponse) => {
        console.log(err.error);
      }
    ));
  }

  deleteEvent(id: string): Observable<boolean> {
    this.apiPath=`${this.appConfig.apiEndpoint}/event/deleteEvent?id=${id}`;
    return this.http.delete(this.apiPath, {headers: this.headers}).pipe(map(
      res => {
        if (res) {
          return true;
        }
        return false;
      },
      (err: HttpErrorResponse) => {
        console.log(err.error);
      }
    ));
  } 

}
