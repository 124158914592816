import { Component, OnInit } from '@angular/core';
import { LayoutService } from "../../layout/layout.service";

import { Subscription } from "rxjs";

import { AuthService } from "../../../core/services/auth.service";

@Component({

  selector: 'sa-login-info',
  templateUrl: './login-info.component.html',
})
export class LoginInfoComponent implements OnInit {


  isLoggedIn = false;
  subscription: Subscription | null = null;
  fullName = "";

  constructor(private authService: AuthService, private layoutService: LayoutService) { }

  ngOnInit() {
    this.subscription = this.authService.authStatus$.subscribe(status => {
      this.isLoggedIn = status;
      if (status) {
        const authUser = this.authService.getAuthUser();
        this.fullName = authUser ? authUser.fullName : "";
      }
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  logout() {
    this.authService.logout(true);
  }

  toggleShortcut() {
    this.layoutService.onShortcutToggle()
  }

}
