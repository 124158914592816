import { Component, Input, OnInit, ViewChild, Output, EventEmitter } from '@angular/core'
import { AppComponent } from '../../../app.component'
import { LangChangeEvent, TranslateService} from '@ngx-translate/core'

@Component({
	selector: 'app-calendar',
	templateUrl: './calendar.component.html',
	styleUrls: ['./calendar.component.css']
})
export class CalendarComponent implements OnInit {
	@Input() events: any[]

	@Output ()
	updateThisCalendar:EventEmitter<string> = new EventEmitter<string>();
	days: any[] = []
	cloading: boolean = true;
	month: number = new Date().getMonth()
	currentMonth: string = new Date().toLocaleDateString(this._appComponent.getLanguage(), {
		month: 'long'
	})
	currentYear = new Date().getFullYear();

	constructor(
		private _appComponent: AppComponent,
		private translate: TranslateService
		) {
			
		}
	ngOnInit() {
		this.getMonthDays('first')
		this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
			this.todayOnClick()
		  })
	}

	todayOnClick() {
		let y = new Date()
		this.month = y.getMonth()
		this.currentMonth = new Date(
			new Date().setMonth(this.month)
		).toLocaleDateString(this._appComponent.getLanguage(), {
			month: 'long'
		})
		this.getMonthDays()
	}

	getMonthDays(type = '') {
		this.days = [];
		if (type == 'inc') {
			if(this.month === 11){
				this.month = 0;
				this.currentYear++;
			}else{
				this.month++
			}
			this.currentMonth = new Date(
				new Date().setMonth(this.month)
			).toLocaleDateString(this._appComponent.getLanguage(), {
				month: 'long'
			})
		} else if (type == 'dec') {
			if(this.month === 0){
				this.month = 11;
				this.currentYear--;
			}else{
				this.month--
			}
			this.currentMonth = new Date(
				new Date().setMonth(this.month)
			).toLocaleDateString(this._appComponent.getLanguage(), {
				month: 'long'
			})
		} else {
			this.month = this.month
		}	

		if (type != 'first')
		{			
			var m = this.month+1;
			this.updateThisCalendar.emit(this.currentYear+'-'+("00" +  m).slice(-2));
		}
		this.cloading = true;
		setTimeout(() => { this.bindEvents(); }, 1500);
	}
	bindEvents()
	{		
		this.events.forEach(x => {
			let date = new Date(new Date(x.start).setHours(0, 0, 0, 0))
			let day = new Date(x.start).toLocaleDateString(this._appComponent.getLanguage(), {
				weekday: 'long',
				year: 'numeric',
				month: 'long',
				day: 'numeric'
			})

			if (x.end) {
				let endDate = new Date(new Date(x.end).setHours(0, 0, 0, 0))
				let endDay = new Date(x.end).toLocaleDateString(this._appComponent.getLanguage(), {
					weekday: 'long',
					year: 'numeric',
					month: 'long',
					day: 'numeric'
				})
				if (
					this.days
						.map(e => {
							return e.day
						})
						.indexOf(endDay) == -1 &&
					endDate.getMonth() == this.month &&
					endDate.getFullYear() == this.currentYear
				) {
					this.days.push({ day: endDay, date: endDate })
				}
			}
			if (
				this.days
					.map(e => {
						return e.day
					})
					.indexOf(day) == -1 &&
				date.getMonth() == this.month &&
				date.getFullYear() == this.currentYear
			) {
				this.days.push({ day: day, date: date })
			}
		},
		this.cloading = false
		);
		this.days.sort((a, b) => {
			return new Date(a.date).getDate() - new Date(b.date).getDate()
		})
	}
	getTodayEvents(date) {
		let today = new Date(date.setHours(0, 0, 0, 0))
		return this.events
			.filter(x => {
				return (
					new Date(new Date(x.start).setHours(0, 0, 0, 0)).getTime() ==
					today.getTime()
				)
			})
			.concat(
				this.events.filter(x => {
					return (
						new Date(new Date(x.end).setHours(0, 0, 0, 0)).getTime() ==
						today.getTime()
					)
				})
			)
	}

	@Output() hideCalendar = new EventEmitter();
	changeCalendarVisibility(){
	  this.hideCalendar.emit();
	}


}
