import { HttpClient, HttpErrorResponse, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject ,  Observable } from 'rxjs';
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
import { catchError, finalize, map } from 'rxjs/operators';

import { AuthTokenType } from './../models/auth-token-type';
import { SMTPConfiguration } from './../models/smtp-configuration';
import { ApiConfigService } from './api-config.service';
import { APP_CONFIG, IAppConfig } from './app.config';
import { TokenStoreService } from './token-store.service';
import { FiletrackingResponse, FiletrackingRequest } from '../models/file-tracking';
import { FiletrackingDocumentsResponse } from '../models/file-tracking-document';
import { DamageDocumentResponse } from '../models/damage-document';

@Injectable()
export class FileTrackingService {
	private headers = new HttpHeaders();
	private apiPath: string;

	constructor(
		private http: HttpClient,
		private router: Router,
		@Inject(APP_CONFIG) private appConfig: IAppConfig,
		private apiConfigService: ApiConfigService,
		private tokenStoreService: TokenStoreService
	) {
		this.headers = this.getBearerAuthHeader();
	}
	getBearerAuthHeader(): HttpHeaders {
		return new HttpHeaders({
			'Content-Type': 'application/json',
			Authorization: `Bearer ${this.tokenStoreService.getRawAuthToken(AuthTokenType.AccessToken)}`
		});
	}
	uploadDamageDocument(formData: FormData, offerId: string) {
		const uploadReq = new HttpRequest(
			'POST',
			`${this.appConfig.apiEndpoint}/filetracking/uploadDamageDocument?offerId=${offerId}`,
			formData,
			{
				reportProgress: true
			}
		);
		return this.http.request(uploadReq);
	}
	uploadFileTrackingDocuments(offerId: string, formData: FormData) {
		const uploadReq = new HttpRequest(
			'POST',
			`${this.appConfig.apiEndpoint}/filetracking/uploadFileTrackingDocuments?offerId=${offerId}`,
			formData,
			{
				reportProgress: true
			}
		);
		return this.http.request(uploadReq);
	}
	getFileTrackings(companyId: string): Observable<FiletrackingResponse[]> {
		this.apiPath = `${this.appConfig.apiEndpoint}/filetracking/getFileTrackings?companyId=${companyId}`;
		return this.http.get<FiletrackingResponse[]>(this.apiPath, { headers: this.headers });
	}

	getFtEvents(companyId: string) : Observable<any[]>{
		this.apiPath = `${this.appConfig.apiEndpoint}/filetracking/getFtEvents?companyId=${companyId}`;
		return this.http.get<any[]>(this.apiPath, { headers: this.headers });
	}

	downloadShipmentInstructions(filetracking: FiletrackingResponse): Observable<string> {
		this.apiPath = `${this.appConfig.apiEndpoint}/filetracking/downloadShipmentInstructions`;
		return this.http.post<string>(this.apiPath, JSON.stringify(filetracking), { headers: this.headers });
	}
	downloadShippingAdvice(filetracking: FiletrackingResponse): Observable<string> {
		this.apiPath = `${this.appConfig.apiEndpoint}/filetracking/downloadShippingAdvice`;
		return this.http.post<string>(this.apiPath, JSON.stringify(filetracking), { headers: this.headers });
	}
	deleteFileTrackingDocument(id: string): Observable<boolean> {
		this.apiPath = `${this.appConfig.apiEndpoint}/filetracking/deleteFileTrackingDocument?id=${id}`;
		return this.http.delete<boolean>(this.apiPath, { headers: this.headers });
	}
	deleteDamageDocument(id: string): Observable<boolean> {
		this.apiPath = `${this.appConfig.apiEndpoint}/filetracking/deleteDamageDocument?id=${id}`;
		return this.http.delete<boolean>(this.apiPath, { headers: this.headers });
	}
	getFileTrackingDocuments(offerId: string): Observable<FiletrackingDocumentsResponse[]> {
		this.apiPath = `${this.appConfig.apiEndpoint}/filetracking/getFileTrackingDocuments?offerId=${offerId}`;
		return this.http.get<FiletrackingDocumentsResponse[]>(this.apiPath, { headers: this.headers });
	}
	getDamageDocuments(offerId: string): Observable<DamageDocumentResponse[]> {
		this.apiPath = `${this.appConfig.apiEndpoint}/filetracking/getDamageDocuments?offerId=${offerId}`;
		return this.http.get<DamageDocumentResponse[]>(this.apiPath, { headers: this.headers });
	}
	getFileTracking(id: string): Observable<FiletrackingResponse> {
		this.apiPath = `${this.appConfig.apiEndpoint}/filetracking/getFileTracking?id=${id}`;
		return this.http.get<FiletrackingResponse>(this.apiPath, { headers: this.headers });
	}

	addFileTracking(fileTracking: FiletrackingRequest): Observable<boolean> {
		this.apiPath = `${this.appConfig.apiEndpoint}/filetracking/addFileTracking`;
		return this.http.post(this.apiPath, fileTracking, { headers: this.headers }).pipe(
			map(
				(res) => {
					if (res) {
						return true;
					}
					return false;
				},
				(err: HttpErrorResponse) => {
					console.log(err.error);
				}
			)
		);
	}

	updateFileTracking(fileTracking: FiletrackingResponse): Observable<boolean> {
		this.apiPath = `${this.appConfig.apiEndpoint}/filetracking/updateFileTracking`;
		return this.http.put(this.apiPath, JSON.stringify(fileTracking), { headers: this.headers }).pipe(
			map(
				(res) => {
					if (res) {
						return true;
					}
					return false;
				},
				(err: HttpErrorResponse) => {
					console.log(err.error);
				}
			)
		);
	}

	deleteFileTracking(id: string): Observable<boolean> {
		this.apiPath = `${this.appConfig.apiEndpoint}/filetracking/deleteFileTracking?id=${id}`;
		return this.http.delete(this.apiPath, { headers: this.headers }).pipe(
			map(
				(res) => {
					if (res) {
						return true;
					}
					return false;
				},
				(err: HttpErrorResponse) => {
					console.log(err.error);
				}
			)
		);
	}
}
