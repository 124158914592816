import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CustomerResponse } from 'app/core/models/customer';
import { CompanyService } from 'app/core/services/company.service';
import { fromEvent } from 'rxjs';
import { filter, debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';


@Component({
  selector: 'app-secust',
  templateUrl: './secust.component.html',
  styleUrls: ['./secust.component.css']
})
export class SecustComponent implements OnInit, AfterViewInit {
  customers: CustomerResponse[];
  // @Input() customerId: string
  @Input() customerName: string;
  cListHide: boolean
  @Input() companyId: string
  @Input() custType: string
  @Output() triggerCustomerSelected: EventEmitter<CustomerResponse> = new EventEmitter<CustomerResponse>();
  @Output() triggerClear: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('sBox') sBox: ElementRef;
  cloading: boolean = false;

  constructor(
    private _companyService: CompanyService,
  ) {
  }


  getCharacters(searchText) {
   // if (searchText.length > 2) {
      this.cListHide = false
      this.cloading = true
      this._companyService.getCustomersByName(this.companyId, searchText, this.custType).subscribe((data: CustomerResponse[]) => {
        this.customers = data    
        this.cloading = false    
      });
    //}
  }

  customerSelected(pId: string) {
    var c = this.customers.filter(cust => cust.Id == pId)[0]

    if (this.custType == 'Bireysel') {
      this.customerName = c.Name + ' ' + c.Surname
    }
    else {
      this.customerName = c.CompanyName
    }
    this.cListHide = true
    this.triggerCustomerSelected.emit(c);
  }
  clearClicked()
  {
    this.customerName = "";
    //this.cListHide = !this.cListHide;
    this.sBox.nativeElement.value = "";
    this.customers = null
    this.triggerClear.emit();
  }
  searchClicked() {
    this.cListHide = !this.cListHide;
    this.sBox.nativeElement.value = "";
    this.customers = null
  }
  closeSearchBar() {
    this.cListHide = true;
  }

  ngOnInit() {
    this.cListHide = true;
  }
  ngAfterViewInit() {
    // server-side search
    fromEvent(this.sBox.nativeElement, 'keyup')
      .pipe(
        filter(Boolean),
        debounceTime(1000),
        distinctUntilChanged(),
        tap((event: KeyboardEvent) => {
          this.getCharacters(this.sBox.nativeElement.value);
        })
      )
      .subscribe();
  }
}
