import { HttpClient, HttpErrorResponse, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OfferNote } from 'app/offer/notes/notes.component';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CompanyDocuments } from '../models/company-documents';
import { UpdateCustomerTitle } from '../models/CustomerTitle';
import { AuthTokenType } from './../models/auth-token-type';
import { CompanyResponse } from './../models/company';
import { CompanyLicense } from './../models/company-license';
import { CompanyTemplate } from './../models/company-template';
import { CustomerRequest, CustomerResponse } from './../models/customer';
import { DepartmentRequest, DepartmentResponse } from './../models/department';
import { PersonelTypeRequest, PersonelTypeResponse } from './../models/personel-type';
import { SMTPConfiguration } from './../models/smtp-configuration';
import { StaffRequest, StaffResponse } from './../models/staff';
import { ApiConfigService } from './api-config.service';
import { APP_CONFIG, IAppConfig } from './app.config';
import { TokenStoreService } from './token-store.service';

@Injectable()
export class CompanyService {
	private headers = new HttpHeaders()
	private apiPath: string

	constructor(
		private http: HttpClient,
		private router: Router,
		@Inject(APP_CONFIG) private appConfig: IAppConfig,
		private apiConfigService: ApiConfigService,
		private tokenStoreService: TokenStoreService
	) {
		this.headers = this.getBearerAuthHeader()
	}

	getBearerAuthHeader(): HttpHeaders {
		return new HttpHeaders({
			'Content-Type': 'application/json',
			Authorization: `Bearer ${this.tokenStoreService.getRawAuthToken(
				AuthTokenType.AccessToken
			)}`
		})
	}
	updateCompanyDocuments(
		companyDocuments: CompanyDocuments
	): Observable<boolean> {
		this.apiPath = `${this.appConfig.apiEndpoint
			}/company/updateCompanyDocuments`
		return this.http
			.post(this.apiPath, JSON.stringify(companyDocuments), {
				headers: this.headers
			})
			.pipe(
				map(
					res => {
						if (res) {
							return true
						}
						return false
					},
					(err: HttpErrorResponse) => {
						console.log(err.error)
					}
				)
			)
	}
	getCompanyDocuments(companyId: number): Observable<CompanyDocuments> {
		this.apiPath = `${this.appConfig.apiEndpoint
			}/company/getCompanyDocuments?companyId=${companyId}`
		return this.http.get<CompanyDocuments>(this.apiPath, {
			headers: this.headers
		})
	}

	getCompanies(): Observable<CompanyResponse[]> {
		this.apiPath = `${this.appConfig.apiEndpoint}/company/getCompanies`
		return this.http.get<CompanyResponse[]>(this.apiPath, {
			headers: this.headers
		})
	}

	getCompaniesByParent(
		parentCompanyId?: string
	): Observable<CompanyResponse[]> {
		this.apiPath = `${this.appConfig.apiEndpoint
			}/company/getCompaniesByParent?parentCompanyId=${parentCompanyId}`
		return this.http.get<CompanyResponse[]>(this.apiPath, {
			headers: this.headers
		})
	}

	getCompaniesWithParent(
		parentCompanyId: string
	): Observable<CompanyResponse[]> {
		this.apiPath = `${this.appConfig.apiEndpoint
			}/company/getCompaniesWithParent?parentCompanyId=${parentCompanyId}`
		return this.http.get<CompanyResponse[]>(this.apiPath, {
			headers: this.headers
		})
	}

	getCompany(id: string): Observable<CompanyResponse> {
		this.apiPath = `${this.appConfig.apiEndpoint}/company/getCompany?id=${id}`
		return this.http.get<CompanyResponse>(this.apiPath, {
			headers: this.headers
		})
	}

	addCompany(company: CompanyResponse): Observable<boolean> {
		this.apiPath = `${this.appConfig.apiEndpoint}/company/addCompany`
		return this.http
			.post(this.apiPath, company, { headers: this.headers })
			.pipe(
				map(
					res => {
						if (res) {
							return true
						}
						return false
					},
					(err: HttpErrorResponse) => {
						console.log(err.error)
					}
				)
			)
	}

	addOfferNote(offerNote: OfferNote): Observable<boolean> {
		this.apiPath = `${this.appConfig.apiEndpoint}/company/addOfferNote`
		return this.http
			.post(this.apiPath, offerNote, { headers: this.headers })
			.pipe(
				map(
					res => {
						if (res) {
							return true
						}
						return false
					},
					(err: HttpErrorResponse) => {
						console.log(err.error)
					}
				)
			)
	}
	updateOfferNote(offerNote: OfferNote): Observable<boolean> {
		this.apiPath = `${this.appConfig.apiEndpoint}/company/updateOfferNote`
		return this.http
			.post(this.apiPath, offerNote, { headers: this.headers })
			.pipe(
				map(
					res => {
						if (res) {
							return true
						}
						return false
					},
					(err: HttpErrorResponse) => {
						console.log(err.error)
					}
				)
			)
	}

	getWebsiteInfo(companyId: number) {
		this.apiPath = `${this.appConfig.apiEndpoint}/company/getWebsiteInfo?companyId=${companyId}`;
		return this.http.get<any>(this.apiPath, {
			headers: this.headers
		})
	}

	deleteOfferNote(offerNote: OfferNote): Observable<boolean> {
		this.apiPath = `${this.appConfig.apiEndpoint}/company/deleteOfferNote`
		return this.http
			.post(this.apiPath, offerNote, { headers: this.headers })
			.pipe(
				map(
					res => {
						if (res) {
							return true
						}
						return false
					},
					(err: HttpErrorResponse) => {
						console.log(err.error)
					}
				)
			)
	}

	updateCompany(company: CompanyResponse): Observable<boolean> {
		this.apiPath = `${this.appConfig.apiEndpoint}/company/updateCompany`
		return this.http
			.put(this.apiPath, JSON.stringify(company), { headers: this.headers })
			.pipe(
				map(
					res => {
						if (res) {
							return true
						}
						return false
					},
					(err: HttpErrorResponse) => {
						console.log(err.error)
					}
				)
			)
	}

	updateCompanyWebsite(companyWebSite: any): Observable<boolean> {
		this.apiPath = `${this.appConfig.apiEndpoint}/company/updateCompanyWebSite`
		return this.http
			.put(this.apiPath, JSON.stringify(companyWebSite), { headers: this.headers })
			.pipe(
				map(
					res => {
						if (res) {
							return true
						}
						return false
					},
					(err: HttpErrorResponse) => {
						console.log(err.error)
					}
				)
			)
	}


	deleteCompany(id: string): Observable<boolean> {
		this.apiPath = `${this.appConfig.apiEndpoint
			}/company/deleteCompany?id=${id}`
		return this.http.delete(this.apiPath, { headers: this.headers }).pipe(
			map(
				res => {
					if (res) {
						return true
					}
					return false
				},
				(err: HttpErrorResponse) => {
					console.log(err.error)
				}
			)
		)
	}

	getCustomers(companyId: string): Observable<CustomerResponse[]> {
		this.apiPath = `${this.appConfig.apiEndpoint
			}/company/getCustomers?companyId=${companyId}`
		return this.http.get<CustomerResponse[]>(this.apiPath, {
			headers: this.headers
		})
	}

	getCustomersByName(companyId: string, name: string, type: string): Observable<CustomerResponse[]> {
		this.apiPath = `${this.appConfig.apiEndpoint
			}/company/GetCustomersByName?companyId=${companyId}&name=${name}&type=${type}`
		return this.http.get<CustomerResponse[]>(this.apiPath, {
			headers: this.headers
		})
	}


	getCustomer(id: string): Observable<CustomerResponse> {
		this.apiPath = `${this.appConfig.apiEndpoint}/company/getCustomer?id=${id}`
		return this.http.get<CustomerResponse>(this.apiPath, {
			headers: this.headers
		})
	}
	getCustomersByParentCustomer(id: string): Observable<CustomerResponse[]> {
		this.apiPath = `${this.appConfig.apiEndpoint
			}/company/getCustomersByParentCustomer?parentCustomerId=${id}`
		return this.http.get<CustomerResponse[]>(this.apiPath, {
			headers: this.headers
		})
	}
	getCustomersByParentCompany(parentCompanyId: string)
		: Observable<CustomerResponse[]> {
		this.apiPath = `${this.appConfig.apiEndpoint
			}/company/getCustomersByParentCompany?parentCompanyId=${parentCompanyId}`
		return this.http.get<CustomerResponse[]>(this.apiPath, {
			headers: this.headers
		})
	}
	addCustomer(customer: CustomerRequest): Observable<Object> {
		this.apiPath = `${this.appConfig.apiEndpoint}/company/addCustomer`
		return this.http
			.post(this.apiPath, JSON.stringify(customer), { headers: this.headers })
			.pipe(
				map(
					res => {
						if (res) {
							return res
						}
						return res
					},
					(err: HttpErrorResponse) => {
						console.log(err.error)
					}
				)
			)
	}
	updateCustomer(customer: CustomerResponse): Observable<boolean> {
		this.apiPath = `${this.appConfig.apiEndpoint}/company/updateCustomer`
		return this.http
			.put(this.apiPath, JSON.stringify(customer), { headers: this.headers })
			.pipe(
				map(
					res => {
						if (res) {
							return true
						}
						return false
					},
					(err: HttpErrorResponse) => {
						console.log(err.error)
					}
				)
			)
	}
	getCustomerTitleByCustomerId(Id: Number): Observable<string> {
		this.apiPath = `${this.appConfig.apiEndpoint
			}/company/getCustomerTitle?Id=${Id}`
		return this.http.get<string>(this.apiPath, {
			headers: this.headers
		})
	}
	updateCustomerTitle(customerTitle: UpdateCustomerTitle): Observable<boolean> {
		this.apiPath = `${this.appConfig.apiEndpoint}/company/updateCustomerTitle`
		return this.http
			.put(this.apiPath, JSON.stringify(customerTitle), { headers: this.headers })
			.pipe(
				map(
					res => {
						if (res) {
							return true
						}
						return false
					},
					(err: HttpErrorResponse) => {
						console.log(err.error)
					}
				)
			)
	}

	deleteCustomer(id: string): Observable<boolean> {
		this.apiPath = `${this.appConfig.apiEndpoint
			}/company/deleteCustomer?id=${id}`
		return this.http.delete(this.apiPath, { headers: this.headers }).pipe(
			map(
				res => {
					if (res) {
						return true
					}
					return false
				},
				(err: HttpErrorResponse) => {
					console.log(err.error)
				}
			)
		)
	}

	getStaffs(companyId: string): Observable<StaffResponse[]> {
		this.apiPath = `${this.appConfig.apiEndpoint
			}/company/getStaffs?companyId=${companyId}`
		return this.http.get<StaffResponse[]>(this.apiPath, {
			headers: this.headers
		})
	}

	getStaff(id: string): Observable<StaffResponse> {
		this.apiPath = `${this.appConfig.apiEndpoint}/company/getStaff?id=${id}`
		return this.http.get<StaffResponse>(this.apiPath, {
			headers: this.headers
		})
	}

	addStaff(offer: StaffRequest): Observable<boolean> {
		this.apiPath = `${this.appConfig.apiEndpoint}/company/addStaff`
		return this.http
			.post(this.apiPath, JSON.stringify(offer), { headers: this.headers })
			.pipe(
				map(
					res => {
						if (res) {
							return true
						}
						return false
					},
					(err: HttpErrorResponse) => {
						console.log(err.error)
					}
				)
			)
	}
	uploadCustomerDocument(customerId: string, formData: FormData) {
		const uploadReq = new HttpRequest(
			'POST',
			`${this.appConfig.apiEndpoint
			}/company/uploadCustomerDocument?customerId=${customerId}`,
			formData,
			{
				reportProgress: true
			}
		)
		return this.http.request(uploadReq)
	}
	deleteCustomerDocument(customerDocumentId) {
		return this.http.delete(
			`${this.appConfig.apiEndpoint
			}/company/deleteCustomerDocument?customerDocumentId=${customerDocumentId}`,
			{ headers: this.headers }
		)
	}

	updateStaff(staff: StaffResponse): Observable<boolean> {
		this.apiPath = `${this.appConfig.apiEndpoint}/company/updateStaff`
		return this.http
			.put(this.apiPath, JSON.stringify(staff), { headers: this.headers })
			.pipe(
				map(
					res => {
						if (res) {
							return true
						}
						return false
					},
					(err: HttpErrorResponse) => {
						console.log(err.error)
					}
				)
			)
	}

	deleteStaff(id: string): Observable<boolean> {
		this.apiPath = `${this.appConfig.apiEndpoint}/company/deleteStaff?id=${id}`
		return this.http.delete(this.apiPath, { headers: this.headers }).pipe(
			map(
				res => {
					if (res) {
						return true
					}
					return false
				},
				(err: HttpErrorResponse) => {
					console.log(err.error)
				}
			)
		)
	}

	getDepartments(companyId: string): Observable<DepartmentResponse[]> {
		this.apiPath = `${this.appConfig.apiEndpoint
			}/company/getDepartments?companyId=${companyId}`
		return this.http.get<DepartmentResponse[]>(this.apiPath, {
			headers: this.headers
		})
	}

	getDepartment(id: string): Observable<DepartmentResponse> {
		this.apiPath = `${this.appConfig.apiEndpoint
			}/company/getDepartment?id=${id}`
		return this.http.get<DepartmentResponse>(this.apiPath, {
			headers: this.headers
		})
	}

	addDepartment(department: DepartmentRequest): Observable<boolean> {
		this.apiPath = `${this.appConfig.apiEndpoint}/company/addDepartment`
		return this.http
			.post(this.apiPath, JSON.stringify(department), { headers: this.headers })
			.pipe(
				map(
					res => {
						if (res) {
							return true
						}
						return false
					},
					(err: HttpErrorResponse) => {
						console.log(err.error)
					}
				)
			)
	}

	updateDepartment(department: DepartmentResponse): Observable<boolean> {
		this.apiPath = `${this.appConfig.apiEndpoint}/company/updateDepartment`
		return this.http
			.put(this.apiPath, JSON.stringify(department), { headers: this.headers })
			.pipe(
				map(
					res => {
						if (res) {
							return true
						}
						return false
					},
					(err: HttpErrorResponse) => {
						console.log(err.error)
					}
				)
			)
	}

	deleteDepartment(id: string): Observable<boolean> {
		this.apiPath = `${this.appConfig.apiEndpoint
			}/company/deleteDepartment?id=${id}`
		return this.http.delete(this.apiPath, { headers: this.headers }).pipe(
			map(
				res => {
					if (res) {
						return true
					}
					return false
				},
				(err: HttpErrorResponse) => {
					console.log(err.error)
				}
			)
		)
	}

	getSmtpConfiguration(id: string): Observable<SMTPConfiguration> {
		this.apiPath = `${this.appConfig.apiEndpoint
			}/company/getSmtpConfiguration?companyId=${id}`
		return this.http.get<SMTPConfiguration>(this.apiPath, {
			headers: this.headers
		})
	}

	updateSmtpConfiguration(
		smtpConfiguration: SMTPConfiguration
	): Observable<boolean> {
		this.apiPath = `${this.appConfig.apiEndpoint
			}/company/updateSmtpConfiguration`
		return this.http
			.put(this.apiPath, JSON.stringify(smtpConfiguration), {
				headers: this.headers
			})
			.pipe(
				map(
					res => {
						if (res) {
							return true
						}
						return false
					},
					(err: HttpErrorResponse) => {
						console.log(err.error)
					}
				)
			)
	}

	getPersonelTypes(companyId: string): Observable<PersonelTypeResponse[]> {
		this.apiPath = `${this.appConfig.apiEndpoint
			}/company/getPersonelTypes?companyId=${companyId}`
		return this.http.get<PersonelTypeResponse[]>(this.apiPath, {
			headers: this.headers
		})
	}

	getPersonelType(id: string): Observable<PersonelTypeResponse> {
		this.apiPath = `${this.appConfig.apiEndpoint
			}/company/getPersonelType?id=${id}`
		return this.http.get<PersonelTypeResponse>(this.apiPath, {
			headers: this.headers
		})
	}

	addPersonelType(personelType: PersonelTypeRequest): Observable<boolean> {
		this.apiPath = `${this.appConfig.apiEndpoint}/company/addPersonelType`
		return this.http
			.post(this.apiPath, JSON.stringify(personelType), {
				headers: this.headers
			})
			.pipe(
				map(
					res => {
						if (res) {
							return true
						}
						return false
					},
					(err: HttpErrorResponse) => {
						console.log(err.error)
					}
				)
			)
	}

	updatePersonelType(personelType: DepartmentResponse): Observable<boolean> {
		this.apiPath = `${this.appConfig.apiEndpoint}/company/updatePersonelType`
		return this.http
			.put(this.apiPath, JSON.stringify(personelType), {
				headers: this.headers
			})
			.pipe(
				map(
					res => {
						if (res) {
							return true
						}
						return false
					},
					(err: HttpErrorResponse) => {
						console.log(err.error)
					}
				)
			)
	}

	deletePersonelType(id: string): Observable<boolean> {
		this.apiPath = `${this.appConfig.apiEndpoint
			}/company/deletePersonelType?id=${id}`
		return this.http.delete(this.apiPath, { headers: this.headers }).pipe(
			map(
				res => {
					if (res) {
						return true
					}
					return false
				},
				(err: HttpErrorResponse) => {
					console.log(err.error)
				}
			)
		)
	}

	getCompanyLicense(id: string): Observable<CompanyLicense> {
		this.apiPath = `${this.appConfig.apiEndpoint
			}/company/getCompanyLicense?companyId=${id}`
		return this.http.get<CompanyLicense>(this.apiPath, {
			headers: this.headers
		})
	}

	updateCompanyLicense(companyLicense: CompanyLicense): Observable<boolean> {
		this.apiPath = `${this.appConfig.apiEndpoint}/company/updateCompanyLicense`
		return this.http
			.put(this.apiPath, JSON.stringify(companyLicense), {
				headers: this.headers
			})
			.pipe(
				map(
					res => {
						if (res) {
							return true
						}
						return false
					},
					(err: HttpErrorResponse) => {
						console.log(err.error)
					}
				)
			)
	}

	getCompanyTemplate(id: string): Observable<CompanyTemplate> {
		this.apiPath = `${this.appConfig.apiEndpoint
			}/company/getCompanyTemplate?companyId=${id}`
		return this.http.get<CompanyTemplate>(this.apiPath, {
			headers: this.headers
		})
	}

	updateCompanyTemplate(companyTemplate: CompanyTemplate): Observable<boolean> {
		this.apiPath = `${this.appConfig.apiEndpoint}/company/updateCompanyTemplate`
		return this.http
			.put(this.apiPath, JSON.stringify(companyTemplate), {
				headers: this.headers
			})
			.pipe(
				map(
					res => {
						if (res) {
							return true
						}
						return false
					},
					(err: HttpErrorResponse) => {
						console.log(err.error)
					}
				)
			)
	}

	getTemplateFromURL(url: string): Observable<string> {
		this.apiPath = `${this.appConfig.apiEndpoint
			}/company/getTemplateFromURL?url=${url}`
		return this.http.get<string>(this.apiPath, { headers: this.headers })
	}
}
