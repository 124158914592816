/**
 * Created by griga on 7/11/16.
 */

import { ModuleWithProviders } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AuthGuardPermission } from './core/models/auth-guard-permission'
import { AuthGuard } from './core/services/auth.guard'
import { LoginLayoutComponent } from './shared/layout/app-layouts/login-layout.component'
import { MainLayoutComponent } from './shared/layout/app-layouts/main-layout.component'

export const routes: Routes = [
	{
		path: '',
		component: MainLayoutComponent,
		children: [
			{
				path: '',
				redirectTo: 'home',
				pathMatch: 'full'
			},
			{
				path: 'home',
				loadChildren: 'app/+home/home.module#HomeModule',
				data: {
					permission: {
						permittedRoles: ['superadmin', 'parentadmin', 'subadmin']
					} as AuthGuardPermission
				},
				canActivate: [AuthGuard]
			},
			{
				path: 'company',
				loadChildren: 'app/company/company.module#CompanyModule',
				data: {
					pageTitle: 'Şirketler',
					permission: {
						permittedRoles: ['superadmin']
					} as AuthGuardPermission
				},
				canActivate: [AuthGuard]
			},
			{
				path: 'company-documents',
				loadChildren:
					'app/company-documents/edit.module#CompanyDocumentsModule',
				data: {
					pageTitle: 'Dökümanlar',
					permission: {
						permittedRoles: ['superadmin', 'parentadmin', 'subadmin']
					} as AuthGuardPermission
				},
				canActivate: [AuthGuard]
			},
			{
				path: 'customer',
				loadChildren: 'app/customer/customer.module#CustomerModule',
				data: {
					pageTitle: 'Kontaklar',
					permission: {
						permittedRoles: ['superadmin', 'parentadmin', 'subadmin']
					} as AuthGuardPermission
				},
				canActivate: [AuthGuard]
			},
			{
				path: 'staff',
				loadChildren: 'app/staff/staff.module#StaffModule',
				data: {
					pageTitle: 'Personeller',
					permission: {
						permittedRoles: ['superadmin', 'parentadmin', 'subadmin']
					} as AuthGuardPermission
				},
				canActivate: [AuthGuard]
			},
			{
				path: 'discover',
				loadChildren: 'app/discover/discover.module#DiscoverModule',
				data: {
					pageTitle: 'Keşif',
					permission: {
						permittedRoles: ['superadmin', 'parentadmin', 'subadmin']
					} as AuthGuardPermission
				},
				canActivate: [AuthGuard]
			},
			{
				path: 'filetracking',
				loadChildren: 'app/filetracking/filetracking.module#FiletrackingModule',
				data: {
					pageTitle: 'Dosya Takip',
					permission: {
						permittedRoles: ['superadmin', 'parentadmin', 'subadmin']
					} as AuthGuardPermission
				},
				canActivate: [AuthGuard]
			},
			{
				path: 'operation',
				loadChildren: 'app/operation/operation.module#OperationModule',
				data: {
					pageTitle: 'Operasyon',
					permission: {
						permittedRoles: ['superadmin', 'parentadmin', 'subadmin']
					} as AuthGuardPermission
				},
				canActivate: [AuthGuard]
			},
			{
				path: 'offer',
				loadChildren: 'app/offer/offer.module#OfferModule',
				data: {
					pageTitle: 'Teklif',
					permission: {
						permittedRoles: ['superadmin', 'parentadmin', 'subadmin']
					} as AuthGuardPermission
				},
				canActivate: [AuthGuard]
			},
			{
				path: 'report',
				loadChildren: 'app/report/report.module#ReportModule',
				data: {
					pageTitle: 'Rapor',
					permission: {
						permittedRoles: ['superadmin', 'parentadmin', 'subadmin']
					} as AuthGuardPermission
				},
				canActivate: [AuthGuard]
			},
			{
				path: 'invoice',
				loadChildren: 'app/invoice/invoice.module#InvoiceModule',
				data: {
					pageTitle: 'Fatura',
					permission: {
						permittedRoles: ['superadmin', 'parentadmin', 'subadmin']
					} as AuthGuardPermission
				},
				canActivate: [AuthGuard]
			},
			{
				path: 'store',
				loadChildren: 'app/store/store.module#StoreModule',
				data: {
					pageTitle: 'Depo',
					permission: {
						permittedRoles: ['superadmin', 'parentadmin', 'subadmin']
					} as AuthGuardPermission
				},
				canActivate: [AuthGuard]
			},
			{
				path: 'storage',
				loadChildren: 'app/storage/storage.module#StorageModule',
				data: {
					pageTitle: 'Depolama',
					permission: {
						permittedRoles: ['superadmin', 'parentadmin', 'subadmin']
					} as AuthGuardPermission
				},
				canActivate: [AuthGuard]
			},
			{
				path: 'vehicle',
				loadChildren: 'app/vehicle/vehicle.module#VehicleModule',
				data: {
					pageTitle: 'Araç',
					permission: {
						permittedRoles: ['superadmin', 'parentadmin', 'subadmin']
					} as AuthGuardPermission
				},
				canActivate: [AuthGuard]
			},
			{
				path: 'department',
				loadChildren: 'app/department/department.module#DepartmentModule',
				data: {
					pageTitle: 'Departman',
					permission: {
						permittedRoles: ['superadmin', 'parentadmin', 'subadmin']
					} as AuthGuardPermission
				},
				canActivate: [AuthGuard]
			},
			{
				path: 'personeltype',
				loadChildren: 'app/personeltype/personeltype.module#PersonelTypeModule',
				data: {
					pageTitle: 'Personel Tipleri',
					permission: {
						permittedRoles: ['superadmin', 'parentadmin', 'subadmin']
					} as AuthGuardPermission
				},
				canActivate: [AuthGuard]
			},
			{
				path: 'user',
				loadChildren: 'app/user/user.module#UserModule',
				data: {
					pageTitle: 'Kullanıcı',
					permission: {
						permittedRoles: ['superadmin', 'parentadmin', 'subadmin']
					} as AuthGuardPermission
				},
				canActivate: [AuthGuard]
			},
			{
				path: 'email',
				loadChildren: 'app/email/email.module#EmailModule',
				data: {
					pageTitle: 'E-Posta',
					permission: {
						permittedRoles: ['superadmin', 'parentadmin', 'subadmin']
					} as AuthGuardPermission
				},
				canActivate: [AuthGuard]
			},
			{
				path: 'role',
				loadChildren: 'app/role/role.module#RoleModule',
				data: {
					pageTitle: 'Rol',
					permission: {
						permittedRoles: ['superadmin', 'parentadmin', 'subadmin']
					} as AuthGuardPermission
				},
				canActivate: [AuthGuard]
			},
			{
				path: 'website',
				loadChildren: 'app/website/website.module#WebSiteModule',
				data: {
					pageTitle: 'Web Sitesi',
					permission: {
						permittedRoles: ['superadmin', 'parentadmin', 'subadmin']
					} as AuthGuardPermission
				},
				canActivate: [AuthGuard]
			},
			{
				path: 'crm',
				loadChildren: 'app/crm/crm.module#CrmModule',
				data: {
					pageTitle: 'Export Company Data',
					permission: {
						permittedRoles: ['superadmin', 'parentadmin', 'subadmin']
					} as AuthGuardPermission

				},
				canActivate: [AuthGuard]
			}
		]
	},

	{
		path: 'login',
		component: LoginLayoutComponent,
		loadChildren: 'app/login/login.module#LoginModule',
		data: {
			pageTitle: 'Login'
		}
	}
]

export const routing: ModuleWithProviders = RouterModule.forRoot(routes, {
	useHash: true
})
