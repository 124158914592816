import {Component} from '@angular/core';

import {LayoutService} from '../layout.service';

declare var $:any;

@Component({
  selector: 'sa-minify-menu',
  template: `<span class="minifyme" style="background: black;
  border-bottom-left-radius: 15px;
  border-top-left-radius: 15px;" (click)="toggle()">
    <i class="fa fa-arrow-left hit"></i>
</span>`,
})
export class MinifyMenuComponent {

  constructor(private layoutService: LayoutService) {
  }

  toggle() {
    this.layoutService.onMinifyMenu()
  }
}
