import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse, HttpRequest } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject ,  Observable } from "rxjs";
import { ErrorObservable } from "rxjs/observable/ErrorObservable";
import { catchError, finalize, map } from "rxjs/operators";

import { AuthTokenType } from "./../models/auth-token-type";
import { UserResponse, UserRequest } from "./../models/user";
import { UserFamilyProfileResponse, UserFamilyProfileRequest } from "./../models/user-family-profile";
import { ApiConfigService } from "./api-config.service";
import { APP_CONFIG, IAppConfig } from "./app.config";
import { TokenStoreService } from "./token-store.service";

@Injectable()
export class UserService {

  private headers=new HttpHeaders();
  private apiPath: string;

  constructor(
    private http: HttpClient,
    private router: Router,
    @Inject(APP_CONFIG) private appConfig: IAppConfig,
    private apiConfigService: ApiConfigService,
    private tokenStoreService: TokenStoreService
  ) 
  {
    this.headers = this.getBearerAuthHeader();
  }


  getBearerAuthHeader(): HttpHeaders {
    return new HttpHeaders({
      "Content-Type": "application/json",
      "Authorization": `Bearer ${this.tokenStoreService.getRawAuthToken(AuthTokenType.AccessToken)}`
    });
  }
  
	uploadStaffDocument(formData: FormData) {
		const uploadReq = new HttpRequest(
			'POST',
			`${this.appConfig.apiEndpoint}/user/uploadStaffDocuments`,
			formData,
			{
				reportProgress: true
			}
		);
		return this.http.request(uploadReq);
	}
  getUsers(companyId: string): Observable<UserResponse[]> {
    this.apiPath=`${this.appConfig.apiEndpoint}/user/getUsers?companyId=${companyId}`;
    return this.http.get<UserResponse[]>(this.apiPath, {headers: this.headers});
  }

  getUser(id: string): Observable<UserResponse> {
    this.apiPath=`${this.appConfig.apiEndpoint}/user/getUser?id=${id}`;
    return this.http.get<UserResponse>(this.apiPath, {headers: this.headers});
  }

  addUser(user: UserRequest): Observable<boolean> {
    this.apiPath=`${this.appConfig.apiEndpoint}/user/addUser`;
    return this.http.post(this.apiPath, JSON.stringify(user), {headers: this.headers}).pipe(map(
      res => {
        if (res) {
          return true;
        }
        return false;
      },
      (err: HttpErrorResponse) => {
        console.log(err.error);
      }
    ));
  }   

  updateUser(user: UserResponse,passwordChanged:boolean): Observable<boolean> {
    this.apiPath=`${this.appConfig.apiEndpoint}/user/updateUser?passwordChanged=`+passwordChanged;
    return this.http.put(this.apiPath, JSON.stringify(user), {headers: this.headers}).pipe(map(
      res => {
        if (res) {
          return true;
        }
        return false;
      },
      (err: HttpErrorResponse) => {
        console.log(err.error);
      }
    ));
  } 

  deleteUser(id: string): Observable<boolean> {
    this.apiPath=`${this.appConfig.apiEndpoint}/user/deleteUser?id=${id}`;
    return this.http.delete(this.apiPath, {headers: this.headers}).pipe(map(
      res => {
        if (res) {
          return true;
        }
        return false;
      },
      (err: HttpErrorResponse) => {
        console.log(err.error);
      }
    ));
  } 
  
  getFamilyMembers(userId: string): Observable<UserFamilyProfileResponse[]> {
    this.apiPath=`${this.appConfig.apiEndpoint}/user/getFamilyMembers?userId=${userId}`;
    return this.http.get<UserFamilyProfileResponse[]>(this.apiPath, {headers: this.headers});
  }

  getFamilyMember(familyMemberId: string): Observable<UserFamilyProfileResponse> {
    this.apiPath=`${this.appConfig.apiEndpoint}/user/getFamilyMember?familyMemberId=${familyMemberId}`;
    return this.http.get<UserFamilyProfileResponse>(this.apiPath, {headers: this.headers});
  }

  addFamilyMember(userFamilyProfile: UserFamilyProfileRequest): Observable<boolean> {
    this.apiPath=`${this.appConfig.apiEndpoint}/user/addFamilyMember`;
    return this.http.post(this.apiPath, JSON.stringify(userFamilyProfile), {headers: this.headers}).pipe(map(
      res => {
        if (res) {
          return true;
        }
        return false;
      },
      (err: HttpErrorResponse) => {
        console.log(err.error);
      }
    ));
  }   

  updateFamilyMember(userFamilyProfile: UserFamilyProfileResponse): Observable<boolean> {
    this.apiPath=`${this.appConfig.apiEndpoint}/user/updateFamilyMember`;
    return this.http.put(this.apiPath, JSON.stringify(userFamilyProfile), {headers: this.headers}).pipe(map(
      res => {
        if (res) {
          return true;
        }
        return false;
      },
      (err: HttpErrorResponse) => {
        console.log(err.error);
      }
    ));
  }  

  deleteFamilyMember(id: string): Observable<boolean> {
    this.apiPath=`${this.appConfig.apiEndpoint}/user/deleteFamilyMember?id=${id}`;
    return this.http.delete(this.apiPath, {headers: this.headers}).pipe(map(
      res => {
        if (res) {
          return true;
        }
        return false;
      },
      (err: HttpErrorResponse) => {
        console.log(err.error);
      }
    ));
  } 

  sendPassword(email: string): Observable<boolean> {
    this.apiPath=`${this.appConfig.apiEndpoint}/email/sendPassword?email=${email}`;
    return this.http.post<boolean>(this.apiPath, {headers: this.headers}).pipe(map(
      res => {
        if (res) {
          return true;
        }
        return false;
      },
      (err: HttpErrorResponse) => {
        console.log(err.error);
      }
    ));
  }  
  
}
