import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";

import { Subscription } from "rxjs";

import { AuthService } from "../../../core/services/auth.service";
import { AppComponent } from '../../../app.component';
declare var $: any;

@Component({
  selector: 'sa-header',
  templateUrl: './header.component.html',
  styleUrls:['./header.component.css']
})
export class HeaderComponent implements OnInit {

  isLoggedIn = false;
  subscription: Subscription | null = null;
  fullName = "";
  currentLanguage: string = "tr";
  constructor(private router: Router, private authService: AuthService, private _appComponent: AppComponent) {
  }

  ngOnInit() {

    if (document.cookie.includes('appLang')) {
      let cookieArr = document.cookie.split(';');
      cookieArr.forEach(cookie => {
        if (cookie.includes('appLang')) {
          let strCookie = cookie.trim();
          let lang = strCookie.split('=')[1];
          this.setLanguage(lang);
        }
      });
    }
    this.subscription = this.authService.authStatus$.subscribe(status => {
      this.isLoggedIn = status;
      if (status) {
        const authUser = this.authService.getAuthUser();
        this.fullName = authUser ? authUser.fullName : "";
      }
    });
  }
  setLanguage(lang) {
    this._appComponent.setLanguage(lang);
    let date = new Date(new Date().setFullYear(new Date().getFullYear() + 1))
    document.cookie = "appLang=" + lang + ";expires=" + date
    this.currentLanguage = this._appComponent.getLanguage();
  }
  ngOnDestroy() {
    // prevent memory leak when component is destroyed
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  logout() {
    this.authService.logout(true);
  }

  searchMobileActive = false;

  toggleSearchMobile() {
    this.searchMobileActive = !this.searchMobileActive;

    $('body').toggleClass('search-mobile', this.searchMobileActive);
  }

  onSubmit() {
    this.router.navigate(['/miscellaneous/search']);

  }
}
