import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SecustComponent } from './secust.component';
import { RouterModule } from '../../../../../node_modules/@angular/router';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';

@NgModule({
  exports:[
    SecustComponent,
    TranslatePipe
  ],
  imports: [
    RouterModule,
    CommonModule,
    TranslateModule
  ],
  declarations: [SecustComponent]
})
export class SecustModule { }
