import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse, HttpRequest, HttpEventType } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject ,  Observable } from "rxjs";
import { ErrorObservable } from "rxjs/observable/ErrorObservable";
import { catchError, finalize, map } from "rxjs/operators";

import { AuthTokenType } from "./../models/auth-token-type";
import { ApiConfigService } from "./api-config.service";
import { APP_CONFIG, IAppConfig } from "./app.config";
import { TokenStoreService } from "./token-store.service";
import { OperationResponse, OperationRequest } from "../models/operation";
import { PersonelToOperationRequest } from "../models/personel-to-operation";
import { PersonelResponse } from "../models/personel";
import { OperationDocumentResponse } from "../models/operation-document";

@Injectable()
export class OperationService {

  private headers=new HttpHeaders();
  private apiPath: string;

  constructor(
    private http: HttpClient,
    private router: Router,
    @Inject(APP_CONFIG) private appConfig: IAppConfig,
    private apiConfigService: ApiConfigService,
    private tokenStoreService: TokenStoreService
  ) 
  {
    this.headers = this.getBearerAuthHeader();
  }


  getBearerAuthHeader(): HttpHeaders {
    return new HttpHeaders({
      "Content-Type": "application/json",
      "Authorization": `Bearer ${this.tokenStoreService.getRawAuthToken(AuthTokenType.AccessToken)}`
    });
  }
  getOperations(companyId: string): Observable<OperationResponse[]> {
    this.apiPath=`${this.appConfig.apiEndpoint}/operation/getOperations?companyId=${companyId}`;
    return this.http.get<OperationResponse[]>(this.apiPath, {headers: this.headers});
  }

  getOperationEvents(companyId: string, pDate: string)
  {
    this.apiPath=`${this.appConfig.apiEndpoint}/operation/getOperationEvents?companyId=${companyId}&pDate=${pDate}`;
    return this.http.get<any[]>(this.apiPath, {headers: this.headers});
  }

  getOncomingOperations(companyId:string):Observable<OperationResponse[]>{
    this.apiPath=`${this.appConfig.apiEndpoint}/operation/getOncomingOperations?companyId=${companyId}`;
    return this.http.get<OperationResponse[]>(this.apiPath,{headers:this.headers});
  }
  getOperation(id: string): Observable<OperationResponse> {
    this.apiPath=`${this.appConfig.apiEndpoint}/operation/getOperation?id=${id}`;
    return this.http.get<OperationResponse>(this.apiPath, {headers: this.headers});
  }
  
  getOperationByOfferId(offerId: string): Observable<OperationResponse> {
    this.apiPath=`${this.appConfig.apiEndpoint}/operation/getOperationByOfferId?offerId=${offerId}`;
    return this.http.get<OperationResponse>(this.apiPath, {headers: this.headers});
  }

  addOperation(operation: OperationRequest): Observable<boolean> {
    this.apiPath=`${this.appConfig.apiEndpoint}/operation/addOperation`;
    return this.http.post(this.apiPath, JSON.stringify(operation), {headers: this.headers}).pipe(map(
      res => {
        if (res) {
          return true;
        }
        return false;
      },
      (err: HttpErrorResponse) => {
        console.log(err.error);
      }
    ));
  }
  addPersonelsToOperation(personelsToOperations: PersonelToOperationRequest[]): Observable<boolean> {
    this.apiPath=`${this.appConfig.apiEndpoint}/operation/addPersonelsToOperation`;
    return this.http.post(this.apiPath, JSON.stringify(personelsToOperations), {headers: this.headers}).pipe(map(
      res => {
        if (res) {
          return true;
        }
        return false;
      },
      (err: HttpErrorResponse) => {
        console.log(err.error);
      }
    ));
  }   
  getPersonels() : Observable<PersonelResponse[]>{
    this.apiPath=`${this.appConfig.apiEndpoint}/operation/getPersonels`;
    return this.http.get<PersonelResponse[]>(this.apiPath, {headers: this.headers});
  }
  getSelectedPersonels(offerId:string) : Observable<PersonelResponse[]>{
    this.apiPath=`${this.appConfig.apiEndpoint}/operation/getSelectedPersonels?offerId=${offerId}`;
    return this.http.get<PersonelResponse[]>(this.apiPath, {headers: this.headers});
  }
  deletePersonel(pto: PersonelToOperationRequest): Observable<boolean> {
    this.apiPath=`${this.appConfig.apiEndpoint}/operation/deletePersonel`;
    return this.http.post(this.apiPath, JSON.stringify(pto), {headers: this.headers}).pipe(map(
      res => {
        if (res) {
          return true;
        }
        return false;
      },
      (err: HttpErrorResponse) => {
        console.log(err.error);
      }
    ));
  } 
  updateOperation(operation: OperationResponse): Observable<boolean> {
    this.apiPath=`${this.appConfig.apiEndpoint}/operation/updateOperation`;
    return this.http.put(this.apiPath, JSON.stringify(operation), {headers: this.headers}).pipe(map(
      res => {
        if (res) {
          return true;
        }
        return false;
      },
      (err: HttpErrorResponse) => {
        console.log(err.error);
      }
    ));
  } 
  updateSelectedPersonels(pto: PersonelToOperationRequest[]): Observable<boolean> {
    this.apiPath=`${this.appConfig.apiEndpoint}/operation/updateSelectedPersonels`;
    return this.http.post(this.apiPath, JSON.stringify(pto), {headers: this.headers}).pipe(map(
      res => {
        if (res) {
          return true;
        }
        return false;
      },
      (err: HttpErrorResponse) => {
        console.log(err.error);
      }
    ));
  }
  uploadPdf(formData: FormData) {
		const uploadReq = new HttpRequest(
			'POST',
			`${this.appConfig.apiEndpoint}/operation/uploadPdf`,
			formData,
			{
				reportProgress: true
			}
		);
		return this.http.request(uploadReq);
  }
  uploadOperationDocument(formData: FormData,offerId:string) {
		const uploadReq = new HttpRequest(
			'POST',
			`${this.appConfig.apiEndpoint}/operation/uploadPdf?offerId=${offerId}`,
			formData,
			{
				reportProgress: true
			}
		);
		return this.http.request(uploadReq);
  }
  deleteDocument(id: string): Observable<boolean> {
    this.apiPath=`${this.appConfig.apiEndpoint}/operation/deleteDocument?id=${id}`;
    return this.http.delete(this.apiPath, {headers: this.headers}).pipe(map(
      res => {
        if (res) {
          return true;
        }
        return false;
      },
      (err: HttpErrorResponse) => {
        console.log(err.error);
      }
    ));
  }
  getOperationDocuments(offerId: string): Observable<OperationDocumentResponse[]> {
    this.apiPath=`${this.appConfig.apiEndpoint}/operation/getOperationDocuments?offerId=${offerId}`;
    return this.http.get<OperationDocumentResponse[]>(this.apiPath, {headers: this.headers});
  }  
  deleteOperation(id: string): Observable<boolean> {
    this.apiPath=`${this.appConfig.apiEndpoint}/operation/deleteOperation?id=${id}`;
    return this.http.delete(this.apiPath, {headers: this.headers}).pipe(map(
      res => {
        if (res) {
          return true;
        }
        return false;
      },
      (err: HttpErrorResponse) => {
        console.log(err.error);
      }
    ));
  }   
}
