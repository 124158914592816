import { HttpClient, HttpErrorResponse, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { VehicleRequest, VehicleResponse } from '../models/vehicle';
import { AuthTokenType } from './../models/auth-token-type';
import { ApiConfigService } from './api-config.service';
import { APP_CONFIG, IAppConfig } from './app.config';
import { TokenStoreService } from './token-store.service';


@Injectable()
export class VehicleService {
	private headers = new HttpHeaders();
	private apiPath: string;

	constructor(
		private http: HttpClient,
		private router: Router,
		@Inject(APP_CONFIG) private appConfig: IAppConfig,
		private apiConfigService: ApiConfigService,
		private tokenStoreService: TokenStoreService
	) {
		this.headers = this.getBearerAuthHeader();
	}

	getBearerAuthHeader(): HttpHeaders {
		return new HttpHeaders({
			'Content-Type': 'application/json',
			Authorization: `Bearer ${this.tokenStoreService.getRawAuthToken(AuthTokenType.AccessToken)}`
		});
	}

	getVehicles(companyId: string): Observable<VehicleResponse[]> {
		this.apiPath = `${this.appConfig.apiEndpoint}/vehicle/getVehicles?companyId=${companyId}`;
		return this.http.get<VehicleResponse[]>(this.apiPath, { headers: this.headers });
	}
	getEvents(companyId: string, pDate: string): Observable<any[]> {
		this.apiPath = `${this.appConfig.apiEndpoint}/vehicle/getEvents?companyId=${companyId}&pDate=${pDate}`;
		return this.http.get<any[]>(this.apiPath, { headers: this.headers });
	}
	getVehicle(id: string): Observable<VehicleResponse> {
		this.apiPath = `${this.appConfig.apiEndpoint}/vehicle/getVehicle?id=${id}`;
		return this.http.get<VehicleResponse>(this.apiPath, { headers: this.headers });
	}

	getVehicleAvailability(companyId: string, vehicleId: string, pDate: string): Observable<boolean> {
		this.apiPath = `${this.appConfig.apiEndpoint}/vehicle/getVehicleAvailability?companyId=${companyId}&vehicleId=${vehicleId}&pDate=${pDate}`;
		return this.http.get<boolean>(this.apiPath, { headers: this.headers });
	}

	addVehicle(vehicle: VehicleRequest): Observable<boolean> {
		this.apiPath = `${this.appConfig.apiEndpoint}/vehicle/addVehicle`;
		return this.http.post(this.apiPath, JSON.stringify(vehicle), { headers: this.headers }).pipe(
			map(
				(res) => {
					if (res) {
						return true;
					}
					return false;
				},
				(err: HttpErrorResponse) => {
					console.log(err.error);
				}
			)
		);
	}

	updateVehicle(vehicle: VehicleResponse): Observable<boolean> {
		this.apiPath = `${this.appConfig.apiEndpoint}/vehicle/updateVehicle`;
		return this.http.put(this.apiPath, JSON.stringify(vehicle), { headers: this.headers }).pipe(
			map(
				(res) => {
					if (res) {
						return true;
					}
					return false;
				},
				(err: HttpErrorResponse) => {
					console.log(err.error);
				}
			)
		);
	}

	deleteVehicle(id: string): Observable<boolean> {
		this.apiPath = `${this.appConfig.apiEndpoint}/vehicle/deleteVehicle?id=${id}`;
		return this.http.delete(this.apiPath, { headers: this.headers }).pipe(
			map(
				(res) => {
					if (res) {
						return true;
					}
					return false;
				},
				(err: HttpErrorResponse) => {
					console.log(err.error);
				}
			)
		);
	}
	uploadFile(formData: FormData) {
		const uploadReq = new HttpRequest('POST', `${this.appConfig.apiEndpoint}/vehicle/uploadFile`, formData, {
			reportProgress: true,
		});
		return this.http.request(uploadReq);
	}
	deleteFile(fileName: string) {
		this.apiPath = `${this.appConfig.apiEndpoint}/vehicle/deleteFile?fileName=${fileName}`;
		return this.http.delete(this.apiPath, { headers: this.headers }).pipe(
			map(
				(res) => {
					if (res) {
						return true;
					}
					return false;
				},
				(err: HttpErrorResponse) => {
					console.log(err.error);
				}
			)
		);
	}
}
