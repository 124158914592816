import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject ,  Observable } from "rxjs";
import { ErrorObservable } from "rxjs/observable/ErrorObservable";
import { catchError, finalize, map } from "rxjs/operators";

import { AuthTokenType } from "./../models/auth-token-type";
import { EmailTemplateResponse, EmailTemplateRequest } from "./../models/email-template";
import { EmailListResponse, EmailListRequest } from "./../models/email-list";
import { EmailListUserResponse, EmailListUserRequest } from "./../models/email-list-user";
import { ApiConfigService } from "./api-config.service";
import { APP_CONFIG, IAppConfig } from "./app.config";
import { TokenStoreService } from "./token-store.service";

@Injectable()
export class EmailService {

  private headers=new HttpHeaders();
  private apiPath: string;

  constructor(
    private http: HttpClient,
    private router: Router,
    @Inject(APP_CONFIG) private appConfig: IAppConfig,
    private apiConfigService: ApiConfigService,
    private tokenStoreService: TokenStoreService
  ) 
  {
    this.headers = this.getBearerAuthHeader();
  }


  getBearerAuthHeader(): HttpHeaders {
    return new HttpHeaders({
      "Content-Type": "application/json",
      "Authorization": `Bearer ${this.tokenStoreService.getRawAuthToken(AuthTokenType.AccessToken)}`
    });
  }
  

  getEmailTemplates(companyId: string): Observable<EmailTemplateResponse[]> {
    this.apiPath=`${this.appConfig.apiEndpoint}/email/getEmailTemplates?companyId=${companyId}`;
    return this.http.get<EmailTemplateResponse[]>(this.apiPath, {headers: this.headers});
  }

  getEmailTemplate(id: string): Observable<EmailTemplateResponse> {
    this.apiPath=`${this.appConfig.apiEndpoint}/email/getEmailTemplate?id=${id}`;
    return this.http.get<EmailTemplateResponse>(this.apiPath, {headers: this.headers});
  }

  addEmailTemplate(emailTemplate: EmailTemplateRequest): Observable<boolean> {
    this.apiPath=`${this.appConfig.apiEndpoint}/email/addEmailTemplate`;
    return this.http.post(this.apiPath, JSON.stringify(emailTemplate), {headers: this.headers}).pipe(map(
      res => {
        if (res) {
          return true;
        }
        return false;
      },
      (err: HttpErrorResponse) => {
        console.log(err.error);
      }
    ));
  }   

  updateEmailTemplate(emailTemplate: EmailTemplateResponse): Observable<boolean> {
    this.apiPath=`${this.appConfig.apiEndpoint}/email/updateEmailTemplate`;
    return this.http.put(this.apiPath, JSON.stringify(emailTemplate), {headers: this.headers}).pipe(map(
      res => {
        if (res) {
          return true;
        }
        return false;
      },
      (err: HttpErrorResponse) => {
        console.log(err.error);
      }
    ));
  }  

  deleteEmailTemplate(id: string): Observable<boolean> {
    this.apiPath=`${this.appConfig.apiEndpoint}/email/deleteEmailTemplate?id=${id}`;
    return this.http.delete(this.apiPath, {headers: this.headers}).pipe(map(
      res => {
        if (res) {
          return true;
        }
        return false;
      },
      (err: HttpErrorResponse) => {
        console.log(err.error);
      }
    ));
  }

  getEmailLists(companyId: string): Observable<EmailListResponse[]> {
    this.apiPath=`${this.appConfig.apiEndpoint}/email/getEmailLists?companyId=${companyId}`;
    return this.http.get<EmailListResponse[]>(this.apiPath, {headers: this.headers});
  }

  getEmailList(id: string): Observable<EmailListResponse> {
    this.apiPath=`${this.appConfig.apiEndpoint}/email/getEmailList?id=${id}`;
    return this.http.get<EmailListResponse>(this.apiPath, {headers: this.headers});
  }

  addEmailList(emailList: EmailListRequest): Observable<boolean> {
    this.apiPath=`${this.appConfig.apiEndpoint}/email/addEmailList`;
    return this.http.post(this.apiPath, JSON.stringify(emailList), {headers: this.headers}).pipe(map(
      res => {
        if (res) {
          return true;
        }
        return false;
      },
      (err: HttpErrorResponse) => {
        console.log(err.error);
      }
    ));
  }   

  updateEmailList(emailList: EmailListResponse): Observable<boolean> {
    this.apiPath=`${this.appConfig.apiEndpoint}/email/updateEmailList`;
    return this.http.put(this.apiPath, JSON.stringify(emailList), {headers: this.headers}).pipe(map(
      res => {
        if (res) {
          return true;
        }
        return false;
      },
      (err: HttpErrorResponse) => {
        console.log(err.error);
      }
    ));
  }  

  deleteEmailList(id: string): Observable<boolean> {
    this.apiPath=`${this.appConfig.apiEndpoint}/email/deleteEmailList?id=${id}`;
    return this.http.delete(this.apiPath, {headers: this.headers}).pipe(map(
      res => {
        if (res) {
          return true;
        }
        return false;
      },
      (err: HttpErrorResponse) => {
        console.log(err.error);
      }
    ));
  }

  getEmailListUsers(emailListId: string): Observable<EmailListUserResponse[]> {
    this.apiPath=`${this.appConfig.apiEndpoint}/email/getEmailListUsers?emailListId=${emailListId}`;
    return this.http.get<EmailListUserResponse[]>(this.apiPath, {headers: this.headers});
  }

  getEmailListUser(id: string): Observable<EmailListUserResponse> {
    this.apiPath=`${this.appConfig.apiEndpoint}/email/getEmailListUser?id=${id}`;
    return this.http.get<EmailListUserResponse>(this.apiPath, {headers: this.headers});
  }

  addEmailListUser(emailListUser: EmailListUserRequest): Observable<boolean> {
    this.apiPath=`${this.appConfig.apiEndpoint}/email/addEmailListUser`;
    return this.http.post(this.apiPath, JSON.stringify(emailListUser), {headers: this.headers}).pipe(map(
      res => {
        if (res) {
          return true;
        }
        return false;
      },
      (err: HttpErrorResponse) => {
        console.log(err.error);
      }
    ));
  }   

  updateEmailListUser(emailListUser: EmailListUserResponse): Observable<boolean> {
    this.apiPath=`${this.appConfig.apiEndpoint}/email/updateEmailListUser`;
    return this.http.put(this.apiPath, JSON.stringify(emailListUser), {headers: this.headers}).pipe(map(
      res => {
        if (res) {
          return true;
        }
        return false;
      },
      (err: HttpErrorResponse) => {
        console.log(err.error);
      }
    ));
  }  

  deleteEmailListUser(id: string): Observable<boolean> {
    this.apiPath=`${this.appConfig.apiEndpoint}/email/deleteEmailListUser?id=${id}`;
    return this.http.delete(this.apiPath, {headers: this.headers}).pipe(map(
      res => {
        if (res) {
          return true;
        }
        return false;
      },
      (err: HttpErrorResponse) => {
        console.log(err.error);
      }
    ));
  }

  addAllEmailListUsers(companyId: string, emailListId: string): Observable<boolean> {
    this.apiPath=`${this.appConfig.apiEndpoint}/email/addAllEmailListUsers?companyId=${companyId}&emailListId=${emailListId}`;
    return this.http.post(this.apiPath, {headers: this.headers}).pipe(map(
      res => {
        if (res) {
          return true;
        }
        return false;
      },
      (err: HttpErrorResponse) => {
        console.log(err.error);
      }
    ));
  }  

  sendList(emailListId: string): Observable<boolean> {
    this.apiPath=`${this.appConfig.apiEndpoint}/email/sendList?emailListId=${emailListId}`;
    return this.http.post(this.apiPath, {headers: this.headers}).pipe(map(
      res => {
        if (res) {
          return true;
        }
        return false;
      },
      (err: HttpErrorResponse) => {
        console.log(err.error);
      }
    ));
  }  

}
