import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { timeout,catchError } from 'rxjs/operators';

Injectable();
export class AngularInterceptor implements HttpInterceptor {
	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (!req.url.includes('ApiSettings') && !req.url.includes('account/') && !req.url.includes('assets/') && !req.url.includes('updateUser')) {
			return next.handle(req).pipe(timeout(60000)).pipe(catchError((err) => {
				console.error(err);
				return observableThrowError('Timeout error',err);
			}));
		} else {
			return next.handle(req);
		}
	}
}
