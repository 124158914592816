import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse, HttpRequest, HttpEventType } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject ,  Observable } from "rxjs";
import { ErrorObservable } from "rxjs/observable/ErrorObservable";
import { catchError, finalize, map } from "rxjs/operators";

import { AuthTokenType } from "./../models/auth-token-type";
import { ApiConfigService } from "./api-config.service";
import { APP_CONFIG, IAppConfig } from "./app.config";
import { TokenStoreService } from "./token-store.service";
import { DiscoverResponse, DiscoverRequest } from "../models/discover";

@Injectable()
export class DiscoverService {

  private headers=new HttpHeaders();
  private apiPath: string;

  constructor(
    private http: HttpClient,
    private router: Router,
    @Inject(APP_CONFIG) private appConfig: IAppConfig,
    private apiConfigService: ApiConfigService,
    private tokenStoreService: TokenStoreService
  ) 
  {
    this.headers = this.getBearerAuthHeader();
  }


  getBearerAuthHeader(): HttpHeaders {
    return new HttpHeaders({
      "Content-Type": "application/json",
      "Authorization": `Bearer ${this.tokenStoreService.getRawAuthToken(AuthTokenType.AccessToken)}`
    });
  }
  
  uploadPdf(formData:FormData){
    const uploadReq = new HttpRequest("POST",`${this.appConfig.apiEndpoint}/discover/UploadPdf`,formData,{
      reportProgress: true
    });
    return this.http.request(uploadReq);
  }

  getDiscovers(companyId: string): Observable<DiscoverResponse[]> {
    this.apiPath=`${this.appConfig.apiEndpoint}/discover/getDiscovers?companyId=${companyId}`;
    return this.http.get<DiscoverResponse[]>(this.apiPath, {headers: this.headers});
  }

  getDiscover(id: string): Observable<DiscoverResponse> {
    this.apiPath=`${this.appConfig.apiEndpoint}/discover/getDiscover?id=${id}`;
    return this.http.get<DiscoverResponse>(this.apiPath, {headers: this.headers});
  }
  getDiscoverByOfferId(id: string): Observable<DiscoverResponse> {
    this.apiPath=`${this.appConfig.apiEndpoint}/discover/getDiscoverByOfferId?id=${id}`;
    return this.http.get<DiscoverResponse>(this.apiPath, {headers: this.headers});
  }

  addDiscover(discover: DiscoverRequest): Observable<boolean> {
    this.apiPath=`${this.appConfig.apiEndpoint}/discover/addDiscover`;
    return this.http.post(this.apiPath, JSON.stringify(discover), {headers: this.headers}).pipe(map(
      res => {
        if (res) {
          return true;
        }
        return false;
      },
      (err: HttpErrorResponse) => {
        console.log(err.error);
      }
    ));
  }   

  updateDiscover(discover: DiscoverResponse): Observable<boolean> {
    this.apiPath=`${this.appConfig.apiEndpoint}/discover/updateDiscover`;
    return this.http.put(this.apiPath, JSON.stringify(discover), {headers: this.headers}).pipe(map(
      res => {
        if (res) {
          return true;
        }
        return false;
      },
      (err: HttpErrorResponse) => {
        console.log(err.error);
      }
    ));
  } 

  deleteDiscover(id: string): Observable<boolean> {
    this.apiPath=`${this.appConfig.apiEndpoint}/discover/deleteDiscover?id=${id}`;
    return this.http.delete(this.apiPath, {headers: this.headers}).pipe(map(
      res => {
        if (res) {
          return true;
        }
        return false;
      },
      (err: HttpErrorResponse) => {
        console.log(err.error);
      }
    ));
  }   
}
