import { Component, OnInit } from '@angular/core'
import { AuthUser } from '../../../core/models/auth-user'
import { AuthService } from '../../../core/services/auth.service'
import { CompanyService } from '../../../core/services/company.service'

@Component({
	selector: 'sa-navigation',
	templateUrl: './navigation.component.html'	
})
export class NavigationComponent implements OnInit {
	authUser: AuthUser
	mainDocumentsLink = ''
	constructor(
		private authService: AuthService,
		private _companyService: CompanyService
	) {
		this.authUser = this.authService.getAuthUser()
		_companyService
			.getCompanyDocuments(+this.authUser.companyId)
			.subscribe(data => {
				this.mainDocumentsLink =
					data && data.MainDocumentsLink ? data.MainDocumentsLink : ''
			})
	}

	ngOnInit() {}
}
