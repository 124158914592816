import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject ,  Observable } from "rxjs";
import { ErrorObservable } from "rxjs/observable/ErrorObservable";
import { catchError, finalize, map } from "rxjs/operators";

import { AuthTokenType } from "./../models/auth-token-type";
import { RoleRequest, RoleResponse } from "./../models/role";
import { RolePermissionRequest, RolePermissionResponse } from "./../models/role-permission";
import { ApiConfigService } from "./api-config.service";
import { APP_CONFIG, IAppConfig } from "./app.config";
import { TokenStoreService } from "./token-store.service";

@Injectable()
export class RoleService {

  private headers=new HttpHeaders();
  private apiPath: string;

  constructor(
    private http: HttpClient,
    private router: Router,
    @Inject(APP_CONFIG) private appConfig: IAppConfig,
    private apiConfigService: ApiConfigService,
    private tokenStoreService: TokenStoreService
  ) 
  {
    this.headers = this.getBearerAuthHeader();
  }


  getBearerAuthHeader(): HttpHeaders {
    return new HttpHeaders({
      "Content-Type": "application/json",
      "Authorization": `Bearer ${this.tokenStoreService.getRawAuthToken(AuthTokenType.AccessToken)}`
    });
  }

  getRoles(companyId: string): Observable<RoleResponse[]> {
    this.apiPath=`${this.appConfig.apiEndpoint}/role/getRoles?companyId=${companyId}`;
    return this.http.get<RoleResponse[]>(this.apiPath, {headers: this.headers});
  }

  getCustomRoles(companyId: string): Observable<RoleResponse[]> {
    this.apiPath=`${this.appConfig.apiEndpoint}/role/getCustomRoles?companyId=${companyId}`;
    return this.http.get<RoleResponse[]>(this.apiPath, {headers: this.headers});
  }

  getRole(id: string): Observable<RoleResponse> {
    this.apiPath=`${this.appConfig.apiEndpoint}/role/getRole?id=${id}`;
    return this.http.get<RoleResponse>(this.apiPath, {headers: this.headers});
  }

  addRole(role: RoleRequest): Observable<boolean> {
    this.apiPath=`${this.appConfig.apiEndpoint}/role/addRole`;
    return this.http.post(this.apiPath, JSON.stringify(role), {headers: this.headers}).pipe(map(
      res => {
        if (res) {
          return true;
        }
        return false;
      },
      (err: HttpErrorResponse) => {
        console.log(err.error);
      }
    ));
  }   

  updateRole(role: RoleResponse): Observable<boolean> {
    this.apiPath=`${this.appConfig.apiEndpoint}/role/updateRole`;
    return this.http.put(this.apiPath, JSON.stringify(role), {headers: this.headers}).pipe(map(
      res => {
        if (res) {
          return true;
        }
        return false;
      },
      (err: HttpErrorResponse) => {
        console.log(err.error);
      }
    ));
  }  

  deleteRole(id: string): Observable<boolean> {
    this.apiPath=`${this.appConfig.apiEndpoint}/role/deleteRole?id=${id}`;
    return this.http.delete(this.apiPath, {headers: this.headers}).pipe(map(
      res => {
        if (res) {
          return true;
        }
        return false;
      },
      (err: HttpErrorResponse) => {
        console.log(err.error);
      }
    ));
  }

  getRolePermissions(roleId: string): Observable<RolePermissionResponse[]> {
    this.apiPath=`${this.appConfig.apiEndpoint}/role/getRolePermissions?roleId=${roleId}`;
    return this.http.get<RolePermissionResponse[]>(this.apiPath, {headers: this.headers});
  }

  deleteRolePermission(id: string): Observable<boolean> {
    this.apiPath=`${this.appConfig.apiEndpoint}/role/deleteRolePermission?id=${id}`;
    return this.http.delete(this.apiPath, {headers: this.headers}).pipe(map(
      res => {
        if (res) {
          return true;
        }
        return false;
      },
      (err: HttpErrorResponse) => {
        console.log(err.error);
      }
    ));
  }

}
