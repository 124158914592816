import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { AuthTokenType } from './../models/auth-token-type'
import { ApiConfigService } from './api-config.service'
import { APP_CONFIG, IAppConfig } from './app.config'
import { TokenStoreService } from './token-store.service'

@Injectable()
export class WebsiteService {
	private headers = new HttpHeaders()
	private apiPath: string

	constructor(
		private http: HttpClient,
		@Inject(APP_CONFIG) private appConfig: IAppConfig,
		private apiConfigService: ApiConfigService,
		private tokenStoreService: TokenStoreService
	) {
		this.headers = this.getBearerAuthHeader()
	}

	getBearerAuthHeader(): HttpHeaders {
		return new HttpHeaders({
			'Content-Type': 'application/json',
			Authorization: `Bearer ${this.tokenStoreService.getRawAuthToken(
				AuthTokenType.AccessToken
			)}`
		})
	}
	uploadImage(formData: FormData, fileType: string, companyId: number) {
		const uploadReq = new HttpRequest(
			'POST',
			`${
				this.appConfig.apiEndpoint
			}/company/uploadWebsiteImage?fileType=${fileType}&companyId=${companyId}`,
			formData,
			{
				reportProgress: true
			}
		)
		return this.http.request(uploadReq)
	}
}
