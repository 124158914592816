
import {mergeMap, map, filter,  merge } from 'rxjs/operators';
import { Component, ViewContainerRef } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
@Component({
	selector: 'app-root',
	template: '<router-outlet></router-outlet>'
})
export class AppComponent {
	public title = 'app works!'
	public seperator: string = ' | '
	public constructor(
		private viewContainerRef: ViewContainerRef,
		private translate: TranslateService,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private titleService: Title
	) {
		this.translate.setDefaultLang('tr')
		this.init()
	}
	async init() {
		this.titleService.setTitle('')
		await this.router.events.pipe(
			filter(ev => ev instanceof NavigationEnd),
			map(() => this.activatedRoute),
			map(route => {
				while (route.firstChild) { route = route.firstChild }
				return route
			}),
			filter(route => {
				this.titleService.setTitle('')
				return route.outlet === 'primary'
			}),
			mergeMap(route => route.parent.data.pipe(merge(route.data))),)
			.subscribe(x => {
				let pT = this.titleService.getTitle()
				this.titleService.setTitle(
					(pT ? pT : '') +
						(pT !== '' ? this.seperator : '') +
						(x.pageTitle ? x.pageTitle : '')
				)
			})
	}

	setLanguage(lang: string) {
		this.translate.use(lang)
	}
	getLanguage() {
		return this.translate.currentLang
	}
}
