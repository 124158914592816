import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {NotificationService} from "../../utils/notification.service";

declare var $:any;

@Component({
  selector: 'sa-logout',
  template: `
<div id="logout" (click)="showPopup()" class="btn-header transparent pull-right" >
        <span> <a routerlink="/auth/login" title="Sign Out" data-action="userLogout" style="margin-top: -0.01rem !important;background:url('assets/img/exitmdpi.svg') no-repeat;width: 30px;height: 30px;border: 0!important"
                  data-logout-msg="You can improve your security further after logging out by closing this opened browser"></a> </span>
    </div>
  `,
  styles: []
})
export class LogoutComponent implements OnInit {

  constructor(private router: Router,
              private notificationService: NotificationService) { }

  showPopup(){
    this.notificationService.smartMessageBox({
      title : "<i class='fa fa-sign-out txt-color-orangeDark'></i> Çıkış <span class='txt-color-orangeDark'><strong>" + $('#show-shortcut').text() + "</strong></span> ?",
      content : "Çıkış yapmak istediğinizden emin misiniz ?",
      buttons : '[Hayır][Evet]'

    }, (ButtonPressed) => {
      if (ButtonPressed == "Evet") {
        this.logout()
      }
    });
  }

  logout(){
      this.router.navigate(['/login'])
  }

  ngOnInit() {

  }



}
