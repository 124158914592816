import {
	HttpClient,
	HttpErrorResponse,
	HttpHeaders
} from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { StorageRequest, StorageResponse } from '../models/storage'
import { AuthTokenType } from './../models/auth-token-type'
import { ApiConfigService } from './api-config.service'
import { APP_CONFIG, IAppConfig } from './app.config'
import { TokenStoreService } from './token-store.service'

@Injectable()
export class StorageService {
	private headers = new HttpHeaders()
	private apiPath: string

	constructor(
		private http: HttpClient,
		private router: Router,
		@Inject(APP_CONFIG) private appConfig: IAppConfig,
		private apiConfigService: ApiConfigService,
		private tokenStoreService: TokenStoreService
	) {
		this.headers = this.getBearerAuthHeader()
	}

	getBearerAuthHeader(): HttpHeaders {
		return new HttpHeaders({
			'Content-Type': 'application/json',
			Authorization: `Bearer ${this.tokenStoreService.getRawAuthToken(
				AuthTokenType.AccessToken
			)}`
		})
	}

	getStorages(companyId: string): Observable<StorageResponse[]> {
		this.apiPath = `${
			this.appConfig.apiEndpoint
		}/storage/getStorages?companyId=${companyId}`
		return this.http.get<StorageResponse[]>(this.apiPath, {
			headers: this.headers
		})
	}

	getStorage(id: string): Observable<StorageResponse> {
		this.apiPath = `${this.appConfig.apiEndpoint}/storage/getStorage?id=${id}`
		return this.http.get<StorageResponse>(this.apiPath, {
			headers: this.headers
		})
	}

	getStorageByOfferId(offerId: string): Observable<StorageResponse> {
		this.apiPath = `${
			this.appConfig.apiEndpoint
		}/storage/getStorageByOfferId?offerId=${offerId}`
		return this.http.get<StorageResponse>(this.apiPath, {
			headers: this.headers
		})
	}

	addStorage(storage: StorageRequest): Observable<boolean> {
		this.apiPath = `${this.appConfig.apiEndpoint}/storage/addStorage`
		return this.http
			.post(this.apiPath, JSON.stringify(storage), { headers: this.headers })
			.pipe(
				map(
					res => {
						if (res) {
							return true
						}
						return false
					},
					(err: HttpErrorResponse) => {
						console.log(err.error)
					}
				)
			)
	}
	downloadInvoice(storageCostDetails, customerId): Observable<string> {
		this.apiPath =
			`${this.appConfig.apiEndpoint}/storage/downloadInvoice?customerId=` +
			customerId
		return this.http.post<string>(
			this.apiPath,
			JSON.stringify(storageCostDetails),
			{ headers: this.headers }
		)
	}
	updateStorage(storage: StorageResponse): Observable<boolean> {
		this.apiPath = `${this.appConfig.apiEndpoint}/storage/updateStorage`
		return this.http
			.put(this.apiPath, JSON.stringify(storage), { headers: this.headers })
			.pipe(
				map(
					res => {
						if (res) {
							return true
						}
						return false
					},
					(err: HttpErrorResponse) => {
						console.log(err.error)
					}
				)
			)
	}

	deleteStorage(id: string): Observable<boolean> {
		this.apiPath = `${
			this.appConfig.apiEndpoint
		}/storage/deleteStorage?id=${id}`
		return this.http.delete(this.apiPath, { headers: this.headers }).pipe(
			map(
				res => {
					if (res) {
						return true
					}
					return false
				},
				(err: HttpErrorResponse) => {
					console.log(err.error)
				}
			)
		)
	}
}
