import { CommonModule } from '@angular/common'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { APP_INITIALIZER, NgModule, Optional, SkipSelf } from '@angular/core'
import { RouterModule } from '@angular/router'
import {
	AlertModule,
	BsDropdownModule,
	ProgressbarModule,
	TabsModule,
	TooltipModule
} from 'ngx-bootstrap'
import { LayoutService } from '../shared/layout/layout.service'
import { SoundService } from '../shared/sound/sound.service'
import { VoiceControlService } from '../shared/voice-control/voice-control.service'
import { VoiceRecognitionService } from '../shared/voice-control/voice-recognition.service'
import { JsonApiService } from './api/json-api.service'
import { CalendarModule } from './component/calendar/calendar.module'
import { SecustModule } from './component/secust/secust.module'
import { HeaderComponent } from './component/header/header.component'
import { AngularInterceptor } from './guards/interceptor'
import { throwIfAlreadyLoaded } from './guards/module-import-guard'
import { ApiConfigService } from './services/api-config.service'
import { AppConfig, APP_CONFIG } from './services/app.config'
import { AuthGuard } from './services/auth.guard'
import { AuthInterceptor } from './services/auth.interceptor'
import { AuthService } from './services/auth.service'
import { BrowserStorageService } from './services/browser-storage.service'
import { CompanyService } from './services/company.service'
import { DiscoverService } from './services/discover.service'
import { EmailService } from './services/email.service'
import { ExcelService } from './services/excel.service'
import { FileTrackingService } from './services/file-tracking.service'
import { InvoiceService } from './services/invoice.service'
import { OfferService } from './services/offer.service'
import { OperationService } from './services/operation.service'
import { RefreshTokenService } from './services/refresh-token.service'
import { RoleService } from './services/role.service'
import { StorageService } from './services/storage.service'
import { StoreService } from './services/store.service'
import { TokenStoreService } from './services/token-store.service'
import { UserService } from './services/user.service'
import { UtilsService } from './services/utils.service'
import { VehicleService } from './services/vehicle.service'
import { WebsiteService } from './services/website.service'
import { CustomEventService } from './services/customevent.service'

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		TooltipModule.forRoot(),
		BsDropdownModule.forRoot(),
		ProgressbarModule.forRoot(),
		AlertModule.forRoot(),
		TabsModule.forRoot()
	],
	exports: [CalendarModule, SecustModule, HttpClientModule, HeaderComponent],
	declarations: [HeaderComponent],
	providers: [
		[
			{
				provide: HTTP_INTERCEPTORS,
				useClass: AngularInterceptor,
				multi: true
			}
		],
		CustomEventService,
		JsonApiService,
		LayoutService,
		CompanyService,
		EmailService,
		UserService,
		RoleService,
		ExcelService,
		VoiceControlService,
		VoiceRecognitionService,
		SoundService,
		UtilsService,
		BrowserStorageService,
		TokenStoreService,
		RefreshTokenService,
		OfferService,
		DiscoverService,
		FileTrackingService,
		InvoiceService,
		OperationService,
		VehicleService,
		StorageService,
		StoreService,
		WebsiteService,
		{
			provide: APP_CONFIG,
			useValue: AppConfig
		},
		AuthService,
		AuthGuard,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptor,
			multi: true
		},
		ApiConfigService,
		{
			provide: APP_INITIALIZER,
			useFactory: (config: ApiConfigService) => () => config.loadApiConfig(),
			deps: [ApiConfigService],
			multi: true
		}
	]
})
export class CoreModule {
	constructor(
		@Optional()
		@SkipSelf()
		parentModule: CoreModule
	) {
		throwIfAlreadyLoaded(parentModule, 'CoreModule')
	}
}
