import { HttpClient } from '@angular/common/http'
import { ApplicationRef, NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { ModalModule } from 'ngx-bootstrap/modal'
import { NgxMaskModule } from 'ngx-mask'
// App is our top level component
import { AppComponent } from './app.component'
import { APP_RESOLVER_PROVIDERS } from './app.resolver'
/*
 * Platform and Environment providers/directives/pipes
 */
import { routing } from './app.routing'
import { AppState, InternalStateType } from './app.service'
// Core providers
import { CoreModule } from './core/core.module'
import { SmartadminLayoutModule } from './shared/layout/layout.module'
import { SmartadminModule } from './shared/smartadmin.module'

import {ToastModule} from 'ng6-toastr';

// Application wide providers
const APP_PROVIDERS = [...APP_RESOLVER_PROVIDERS, AppState]

interface StoreType {
	state: InternalStateType
	restoreInputValues: () => void
	disposeOldHosts: () => void
}

/**
 * `AppModule` is the main entry point into Angular2's bootstraping process
 */

@NgModule({
	bootstrap: [AppComponent],
	declarations: [AppComponent],
	imports: [
		// import Angular's modules
		BrowserModule,
		BrowserAnimationsModule,
		FormsModule,
		ModalModule.forRoot(),
		CoreModule,
		SmartadminModule,
		SmartadminLayoutModule,
		routing,
		ToastModule.forRoot(),
		NgxMaskModule.forRoot({}),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			}
		})
	],
	exports: [],
	providers: [
		// expose our Services and Providers into Angular's dependency injection
		// ENV_PROVIDERS,
		APP_PROVIDERS
	]
})
export class AppModule {
	constructor(public appRef: ApplicationRef, public appState: AppState) {}
}

export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http)
}
