import {
	HttpClient,
	HttpErrorResponse,
	HttpHeaders
} from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { BehaviorSubject ,  Observable } from 'rxjs'
import { ErrorObservable } from 'rxjs/observable/ErrorObservable'
import { catchError, finalize, map } from 'rxjs/operators'
import { AuthTokenType } from './../models/auth-token-type'
import { AuthUser } from './../models/auth-user'
import { Credentials } from './../models/credentials'
import { ApiConfigService } from './api-config.service'
import { APP_CONFIG, IAppConfig } from './app.config'
import { CompanyService } from './company.service'
import { RefreshTokenService } from './refresh-token.service'
import { TokenStoreService } from './token-store.service'

@Injectable()
export class AuthService {
	private authStatusSource = new BehaviorSubject<boolean>(false)
	authStatus$ = this.authStatusSource.asObservable()

	constructor(
		private http: HttpClient,
		private router: Router,
		@Inject(APP_CONFIG) private appConfig: IAppConfig,
		private apiConfigService: ApiConfigService,
		private tokenStoreService: TokenStoreService,
		private refreshTokenService: RefreshTokenService,
		private companyService: CompanyService
	) {
		this.updateStatusOnPageRefresh()
		this.refreshTokenService.scheduleRefreshToken(this.isAuthUserLoggedIn())
	}

	login(credentials: Credentials): Observable<boolean> {
		const headers = new HttpHeaders({ 'Content-Type': 'application/json' })
		return this.http
			.post(
				`${this.appConfig.apiEndpoint}/${
					this.apiConfigService.configuration.loginPath
				}`,
				credentials,
				{ headers: headers }
			)
			.pipe(
				map((response: any) => {
					this.tokenStoreService.setRememberMe(credentials.rememberMe)
					if (response.statusText == 'User Locked') {
						throw new HttpErrorResponse({
							statusText: 'Kullanıcı bloklanmıştır'
						})
					}
					if (!response) {
						console.error(
							'There is no `{\'' +
								this.apiConfigService.configuration.accessTokenObjectKey +
								'\':\'...\',\'' +
								this.apiConfigService.configuration.refreshTokenObjectKey +
								'\':\'...value...\'}` response after login.'
						)
						this.authStatusSource.next(false)
						return false
					}
					this.tokenStoreService.storeLoginSession(response)
					const authUser = this.getAuthUser()
					if (authUser.companyId != '0') {
						if (new Date(authUser.expirationDate) < new Date()) {
							this.logout(false)
							throw new HttpErrorResponse({
								statusText: 'Şirketinize ait lisansın kullanım süresi geçersiz'
							})
						}
					}

					this.refreshTokenService.scheduleRefreshToken(true)
					this.authStatusSource.next(true)
					return true
				}),
				catchError((error: HttpErrorResponse) => ErrorObservable.create(error))
			)
	}
	async getCompanyInfos() {
		return await this.companyService
			.getCompany(this.getAuthUser().companyId)
			.toPromise()
	}
	getBearerAuthHeader(): HttpHeaders {
		return new HttpHeaders({
			'Content-Type': 'application/json',
			Authorization: `Bearer ${this.tokenStoreService.getRawAuthToken(
				AuthTokenType.AccessToken
			)}`
		})
	}

	logout(navigateToHome: boolean): void {
		const headers = new HttpHeaders({ 'Content-Type': 'application/json' })
		const logoutUser = {
			refreshToken: this.tokenStoreService.getRawAuthToken(
				AuthTokenType.RefreshToken
			)
		}
		this.http
			.post(
				`${this.appConfig.apiEndpoint}/${
					this.apiConfigService.configuration.logoutPath
				}`,
				logoutUser,
				{ headers: headers }
			)
			.pipe(
				map(response => response || {}),
				catchError((error: HttpErrorResponse) => ErrorObservable.create(error)),
				finalize(() => {
					this.tokenStoreService.deleteAuthTokens()
					this.refreshTokenService.unscheduleRefreshToken()
					this.authStatusSource.next(false)
					if (navigateToHome) {
						this.router.navigate(['/login'])
					}
				})
			)
			.subscribe(result => {})
	}

	isAuthUserLoggedIn(): boolean {
		return (
			this.tokenStoreService.hasStoredAccessAndRefreshTokens() &&
			!this.tokenStoreService.isAccessTokenTokenExpired()
		)
	}

	getAuthUser(): AuthUser | null {
		if (!this.isAuthUserLoggedIn()) {
			return null
		}

		const decodedToken = this.tokenStoreService.getDecodedAccessToken()
		if (decodedToken['ExpirationDate']) {
			let result = decodedToken['ExpirationDate']
				.match(/<(.*?)>(.*?)<\/(.*?)>/g)
				.map(function(val) {
					return val.replace(/<\/?(.*?)>/g, '')
				})
			decodedToken['UserLimit'] = result[1]
			decodedToken['ExpirationDate'] = result[2]
		}
		const roles = this.tokenStoreService.getDecodedTokenRoles()
		return Object.freeze({
			userId:
				decodedToken[
					'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'
				],
			companyId: decodedToken['CompanyId'],
			parentCompanyId: decodedToken['ParentCompanyId'],
			email:
				decodedToken[
					'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'
				],
			fullName: decodedToken['FullName'],
			companyLogo: decodedToken['CompanyLogo'],
			userPhoto: decodedToken['UserPhoto'],
			expirationDate: decodedToken['ExpirationDate'],
			userLimit: decodedToken['UserLimit'],
			roles: roles
		})
	}

	isAuthUserInRoles(requiredRoles: string[]): boolean {
		const user = this.getAuthUser()
		if (!user || !user.roles) {
			return false
		}

		return requiredRoles.some(requiredRole => {
			if (user.roles) {
				return user.roles.indexOf(requiredRole.toLowerCase()) >= 0
			} else {
				return false
			}
		})
	}

	isAuthUserInRole(requiredRole: string): boolean {
		return this.isAuthUserInRoles([requiredRole])
	}

	private updateStatusOnPageRefresh(): void {
		this.authStatusSource.next(this.isAuthUserLoggedIn())
	}
}
